import React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import Plus from "../Icons/Plus";
import Minus from "../Icons/Minus";

const DropDown = ({ ...props }) => (
  <div className="w-full mb-12">
    <div className="flex flex-col w-full mx-auto bggradientlanding rounded-2xl divide-y-1/2 divide-gray-500 divide-opacity-25">
      {props.faqs.map((faq) => (
        <Disclosure key={faq.question}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open
                    ? "border-0 divide-y-0 bg-newdarkmode-700 bg-opacity-[40%] first:rounded-t-2xl last:rounded-b-2xl"
                    : ""
                } flex flex-nowrap flex-shrink-0 w-full px-4 py-6 lg:py-8 text-lg text-left text-gray-200 ease-in-out hover:bg-newdarkmode-700 hover:bg-opacity-[40%] hover:first:rounded-t-2xl hover:last:rounded-b-2xl`}
              >
                {open ? (
                  <Minus className="my-auto mr-4 min-w-8 min-h-8 max-w-8 max-h-8" />
                ) : (
                  <Plus className="my-auto mr-4 min-w-8 min-h-8 max-w-8 max-h-8" />
                )}
                <span className="my-auto ml-1.5 text-base font-light xl:text-xl font-integral">
                  {faq.question}
                </span>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="max-w-3xl px-6 py-6 text-base font-light text-gray-300 md:ml-12 xl:text-lg leading-9 lg:px-0 lg:max-w-4xl">
                  {faq.answer}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  </div>
);

export default DropDown;
