/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState } from "react";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";
import TextField from "src/Components/Inputs/TextField";
import useWindowSize from "src/Hooks/useWindowSize";
import { isExtension } from "src/lib";

const CreateUsername = ({ handleChange, ...props }) => {
  const size = useWindowSize();
  const [error, setError] = useState("");
  const { username, vault } = props;

  const submitUsername = () => {
    props.setSteps([
      { name: "Set Password", href: "#", status: "complete" },
      { name: "Create Username", href: "#", status: "complete" },
      { name: "Secure Wallet", href: "#", status: "current" },
      { name: "Confirm Secret Backup Phrase", href: "#", status: "upcoming" },
    ]);
    handleChange("secureWallet");
  };

  const handleSubmit = async () => {
    const { xpubkh } = vault.public;
    if (username && xpubkh) {
      const checkUsernameIsUnique = await fetch(
        `${
          isExtension ? "https://beta.bitmask.app/" : "/"
        }checkusername?lookup=${username.toLowerCase()}`
      );
      const isUnique = await checkUsernameIsUnique.json();

      if (isUnique.success === true) {
        const createUsername = await fetch(
          `${isExtension ? "https://beta.bitmask.app/" : "/"}username`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username: username.toLowerCase(),
              xpubkh,
              xprvkh: vault.private.xprvkh,
            }),
          }
        );
        const creationSuccess = await createUsername.json();
        if (creationSuccess.success === true) {
          window.localStorage.setItem(
            "username",
            `${username.toLowerCase()}@bitmask.app`
          );
          submitUsername();
        } else if (creationSuccess.reason) {
          setError(creationSuccess.reason);
        } else {
          setError("Error creating username");
        }
      } else {
        setError("Username already in use");
      }
    } else {
      setError(
        !username
          ? "Username field cannot be left blank"
          : "Public key hash not found"
      );
    }
  };

  return (
    <div className="w-full h-auto py-10 m-auto mx-3 text-center xs:mx-6 sm:m-auto sm:shadow-xl sm:w-136 rounded-xl xs:bggradientlanding">
      <Circles steps={props.steps} />
      <h1 className="mt-6 text-2xl font-bold text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
        Create Username
      </h1>
      <p className="w-11/12 m-auto mt-2 text-base font-light text-left text-gray-900 sm:text-lg xs:w-9/12 dark:text-gray-400 flex-grow-default leading-7 sm:leading-8">
        Your username allows you to create a handle (ex. username@bitmask.app)
        for use with Lightning Network and Nostr.
      </p>
      <TextField
        type="text"
        className="w-11/12 p-3 mt-2 xs:w-9/12"
        placeholder="Create username"
        onChange={(e) => {
          setError("");
          props.setUsername(e.target.value);
        }}
        darkest={size.width >= 500}
      />
      {error !== "" && (
        <div className="w-11/12 m-auto xs:w-9/12">
          <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
            {error}
          </p>
        </div>
      )}
      <RoundedButton
        className="w-9/12 py-3 mx-auto mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
        onClick={() => handleSubmit()}
      >
        <span className="m-auto">Create username</span>
      </RoundedButton>
    </div>
  );
};

export default CreateUsername;
