/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import { Location } from "src/types";
import { updatingAssets } from "src/aux/updateAssets";
import Mixpanel from "src/lib/Mixpanel";

const ImportDescription = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const [loading, setLoading] = useState(false);
  const {
    walletData,
    vault,
    lnCredentials,
    asset: assetData,
    source,
    hash,
  } = location.state;

  const asset = {
    ticker: assetData.ticker,
    name: assetData.name,
    description: assetData.description,
    amount: assetData.balance,
    id: assetData.contractId,
  };

  Mixpanel.track("Viewed Import Description", {
    Asset: asset,
    Source: source.view,
    Search: source?.param,
  });
  Mixpanel.track("Viewed Import Confirmation", {
    Asset: assetData,
    Source: source.view,
  });

  const handleSubmit = async () => {
    setLoading(true);
    const futureAssets = await updatingAssets(walletData, assetData);
    console.debug(`futureAssets: ${futureAssets}`);
    Mixpanel.track("Imported Asset", {
      Asset: assetData,
      Source: source.view,
    });
    navigate("/wallet", {
      state: { wallet: walletData.name, vault, hash, lnCredentials },
    });
  };

  return (
    <PageWrapper
      title="Import Confirmation"
      handlePageBack={() =>
        navigate("/importassets", {
          state: { walletData, vault, hash, lnCredentials },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={() => handleSubmit()}
      loading={loading}
      confirmation
    >
      <div className="text-black dark:text-white sm:bg-gray-300 sm:dark:bggradient sm:rounded-lg sm:shadow-lg sm:p-12">
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Asset Ticker:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.ticker}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Asset Name:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.name}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Amount:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.amount.toLocaleString()}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            {asset.id ? "Asset ID" : "Genesis ID"}:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.id}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Description:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.description}
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ImportDescription;
