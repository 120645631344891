/* eslint-disable arrow-body-style */
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

const DefaultMenu = ({ ...props }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Menu as="div" className={`${props.className} relative m-auto`}>
      {({ open }) => (
        <>
          <div className="z-30 flex justify-center w-10 h-10 m-auto">
            <Menu.Button
              as="button"
              onClick={(e) => e.stopPropagation()}
              className="flex justify-center m-auto"
              title={props.title}
            >
              <span className="sr-only">Open user menu</span>
              {props.children}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className={classNames(
                props.originBottom
                  ? "-top-6 origin-bottom-right translate-y-full"
                  : "-top-6 origin-top-right -translate-y-full",
                "absolute right-0 w-auto bg-gray-100 shadow-lg dark:bggradient rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none z-999"
              )}
            >
              {props.items.map((item) => (
                <Menu.Item key={props.key ? props.key : item.name}>
                  {({ active }) => (
                    <button
                      type="button"
                      disabled={item.disabled}
                      onClick={(e) => {
                        e.stopPropagation();
                        item.onClick();
                      }}
                      className={classNames(
                        active ? "bg-gray-200 dark:bg-newdarkmode-700" : "",
                        item.hidden ? "hidden" : "",
                        "block py-3 px-12 text-lg font-normal w-full whitespace-nowrap text-center text-gray-900 dark:text-white select-none hover:bg-gray-300 dark:hover:bg-newdarkmode-700 disabled:cursor-default disabled:pointer-events-none disabled:text-gray-600 dark:disabled:text-gray-600 disabled:text-opacity-75 last:rounded-b-md first:rounded-t-md"
                      )}
                    >
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DefaultMenu;
