/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useRef, useCallback } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import { Swiper } from "swiper/react";

const CustomSwiper = ({ ...props }) => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="flex flex-col justify-center w-full h-auto m-auto">
      <div className="flex w-auto px-6 mb-4 text-left lg:m-auto lg:mb-6 flex-nowrap lg:w-11/12">
        <h1 className="mx-auto text-2xl text-center text-white md:text-3xl xl:text-4xl">
          BLOG
        </h1>
      </div>
      <div className="flex flex-row m-auto mx-2">
        <button
          type="button"
          className="hidden my-auto ml-auto cursor-pointer sm:flex"
          onClick={() => handlePrev()}
        >
          <ChevronLeftIcon className="w-16 h-16 text-white" />
        </button>
        <Swiper
          ref={sliderRef}
          modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
          spaceBetween={24}
          slidesPerView={1}
          updateOnWindowResize
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            900: {
              slidesPerView: 2,
            },
            1224: {
              slidesPerView: 3,
            },
            1480: {
              slidesPerView: 4,
            },
          }}
          pagination={{
            clickable: true,
          }}
          className="w-auto"
        >
          {props.children}
        </Swiper>
        <button
          type="button"
          className="hidden my-auto mr-auto cursor-pointer sm:flex"
          onClick={() => handleNext()}
        >
          <ChevronRightIcon className="w-16 h-16 font-normal text-white" />
        </button>
      </div>
    </div>
  );
};

export default CustomSwiper;
