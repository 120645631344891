import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as backend from "bitmask-segwit";

import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import { getConversion, lndhubError } from "src/Hooks/util";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import { isExtension } from "src/lib";

const LightningConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    wallet,
    vault,
    lnCredentials: oldLnCredentials,
    lnInvoice,
    hash,
    usernameInvoice,
  } = location.state;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState(0);
  const [lnCredentials, setLnCredentials] = useState(oldLnCredentials);
  const network = window.localStorage.getItem("network");

  const amount = lnInvoice
    ? // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      require("light-bolt11-decoder").decode(lnInvoice).sections[2].value *
      0.001
    : usernameInvoice.amount;

  const handlePayLightningInvoice = async () => {
    setLoading(true);
    const tokens = await backend.lightning.auth(
      vault.public.xpubkh,
      vault.private.xprvkh
    );
    if ("error" in tokens) {
      setLoading(false);
      console.error("LNDHubX authentication error:", tokens.error);
      setError({
        title: "Error Creating LN Invoice",
        message: `Authentication error: ${tokens.error}`,
      });
      setOpen(true);
    } else {
      const newLnCredentials = {
        login: vault.public.xpubkh,
        password: vault.private.xprvkh,
        refreshToken: tokens.refresh,
        accessToken: tokens.token,
      };
      setLnCredentials(newLnCredentials);
      if (lnInvoice) {
        try {
          const invoice = await backend.lightning.payInvoice(
            lnInvoice,
            newLnCredentials.accessToken
          );
          if (invoice.error) {
            setLoading(false);
            setError({
              title: "Error Creating LN Invoice",
              message: lndhubError(invoice.error),
            });
            setOpen(true);
          } else if (invoice) {
            navigate("/success", {
              state: {
                wallet,
                vault,
                lnCredentials: newLnCredentials,
                lnInvoice,
                hash,
                transaction: { assetType: "Lightning" },
              },
            });
          }
          Mixpanel.track("Viewed LN Transaction Confirmation Page", {
            Transaction: invoice,
          });
        } catch (err) {
          setLoading(false);
          setError({
            title: "Error Paying LN Invoice",
            message:
              (err as Error)?.message ||
              err?.toString() ||
              "Unhandled exception",
          });
          setOpen(true);
        }
      } else {
        try {
          const username = usernameInvoice.username.replace("@bitmask.app", "");
          const createUsernameInvoice = await fetch(
            `${
              isExtension ? "https://beta.bitmask.app/" : "/"
            }pay/${username.toLowerCase()}?amount=${
              usernameInvoice.amount * 1000
            }`
          );
          const invoice = await createUsernameInvoice.json();
          if (invoice.pr) {
            const payInvoice = await backend.lightning.payInvoice(
              invoice.pr,
              newLnCredentials.accessToken
            );
            if (payInvoice.success) {
              navigate("/success", {
                state: {
                  wallet,
                  vault,
                  lnCredentials: null,
                  hash: invoice.payment_hash,
                  transaction: { assetType: "Lightning" },
                },
              });
            } else {
              setLoading(false);
              setError({
                title: "Error Paying LN Invoice",
                message: "An unknown error occurred.",
              });
              setOpen(true);
            }
          } else {
            setLoading(false);
            setError({
              title: "Error Creating LN Invoice",
              message: "An unknown error occurred.",
            });
            setOpen(true);
          }
        } catch (err) {
          setLoading(false);
          setError({
            title: "Error Sending to Lightning Username",
            message:
              (err as Error)?.message ||
              err?.toString() ||
              "Unhandled exception",
          });
          setOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  return (
    <PageWrapper
      title="Confirm Lightning Transaction"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet, vault, lnCredentials, hash },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet, vault, lnCredentials, hash },
        })
      }
      handleSubmit={() => handlePayLightningInvoice()}
      loading={loading}
      confirmation
    >
      <div className="p-3 bg-gray-300 rounded-lg shadow-lg dark:bg-newdarkmode-700 sm:p-6 lg:p-12">
        <div className="flex flex-col w-full p-3 py-6 m-auto text-center sm:w-11/12 md:w-10/12 xs:p-6 space-y-3">
          <h2 className="text-xl font-semibold text-black xs:text-2xl dark:text-gray-300">
            {lnInvoice
              ? "Pay LN Invoice for "
              : `Pay ${usernameInvoice.username} `}
            {Number(amount).toLocaleString()}{" "}
            {network !== "bitcoin" ? "tSats" : "Sats"}
          </h2>
          <h2 className="text-lg font-normal text-gray-800 dark:text-gray-300">
            {Number(Number(amount / 100_000_000)).toLocaleString(undefined, {
              minimumFractionDigits: 8,
            })}{" "}
            {network !== "bitcoin" ? "tBTC" : "BTC"}
          </h2>
          <h2 className="text-lg font-normal text-gray-800 dark:text-gray-300">
            {Number(Number(amount / 100_000_000) * conversion).toLocaleString(
              undefined,
              { minimumFractionDigits: 3 }
            )}{" "}
            USD
          </h2>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default LightningConfirm;
