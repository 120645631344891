import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as backend from "bitmask-segwit";

import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import Mixpanel from "src/lib/Mixpanel";
import CopyButton from "src/Components/Buttons/CopyButton";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Loader from "src/Components/Loaders/Loader";
import SideOptions from "src/Components/FAQ/SideOptions";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import { isExtension } from "src/lib";
import { getHash } from "src/Hooks/util";

const AccountDetails = () => {
  const navigate = useNavigate();
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [vault, setVault] = useState<backend.bitcoin.Vault>();
  const [nostrPubkey, setNostrPubkey] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const network = window.localStorage.getItem("network");
  const options = [
    {
      name: "Bitcoin",
    },
    {
      name: "Nostr",
    },
    {
      name: "User",
    },
  ];
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    Mixpanel.track("Viewed Settings");
    const getVault = async () => {
      const hash = await getHash();
      const encryptedDescriptors = window.localStorage.getItem(
        `${network}Descriptor`
      );
      if (hash) {
        if (!encryptedDescriptors) {
          throw new Error("No descriptors found");
        }
        const vlt = await backend.bitcoin.decryptWallet(
          hash,
          encryptedDescriptors
        );
        setVault(vlt);
      }
    };
    getVault();
  }, []);

  if (!vault) {
    return (
      <div className="flex justify-center w-full h-full m-auto text-gray-900 min-h-page dark:text-gray-300">
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  const carbonadoNpub = `${
    vault.public.nostrPub.charAt(1) === "2" ? "+" : "-"
  }${vault.public.nostrNpub}`;

  return (
    <PageWrapper2
      className="h-auto"
      title="Account Details"
      handlePageBack={() => navigate("/")}
    >
      <div className="flex flex-col w-full h-full mx-auto my-3 xs:p-2 md:w-11/12 sm:bg-gray-200 sm:dark:bggradient sm:rounded-lg sm:shadow-lg pb-9 sm:p-9 md:my-9">
        <div className="mb-6">
          <SideOptions
            options={options}
            value={selected}
            setValue={setSelected}
            lessMargin
          />
        </div>
        <div className="flex flex-col w-full px-4 space-y-4 xs:space-y-6">
          {selected.name === "Bitcoin" && (
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col">
                <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
                  xpub for Watch-only Bitcoin Wallet
                </p>
                <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
                  Can be loaded into mobile wallets like{" "}
                  <a
                    className="w-auto m-auto text-xs font-light text-black underline xs:text-sm sm:text-base dark:text-gray-500"
                    href="https://bluewallet.io"
                  >
                    Blue Wallet
                  </a>{" "}
                  to track incoming payments. Note: An extended public key can
                  be used to derive every address your wallet generates. This
                  has privacy considerations. Be careful who and what you share
                  it with.
                </p>
                <CopyButton
                  className="w-full"
                  title="xpub"
                  hideTitle
                  darker
                  handleOnClick={() => {
                    navigator.clipboard.writeText(vault.public.xpub);
                    setOpenCopyModal(true);
                  }}
                >
                  {vault.public.xpub}
                </CopyButton>
              </div>
              <div className="flex flex-col">
                <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
                  xpubkh for DIBA wallet marketplace connection
                </p>
                <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
                  An extended public key hash is a non-standard way to create a
                  unique wallet identifier. This is used to connect with the
                  DIBA marketplace, and can be checked against the value shown
                  on the{" "}
                  <a
                    className="w-auto m-auto text-xs font-light text-black underline xs:text-sm sm:text-base dark:text-gray-500"
                    href="https://diba.io/connect"
                  >
                    Manage Bitcoin Wallets
                  </a>{" "}
                  page on diba.io. As a hash, it reveals no wallet details.
                </p>
                <CopyButton
                  className="w-full"
                  title="xpubkh"
                  hideTitle
                  darker
                  handleOnClick={() => {
                    navigator.clipboard.writeText(vault.public.xpubkh);
                    setOpenCopyModal(true);
                  }}
                >
                  {vault.public.xpubkh}
                </CopyButton>
              </div>{" "}
            </div>
          )}
          {selected.name === "Nostr" && (
            <div className="flex flex-col">
              <div className="flex flex-col">
                <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
                  Default wallet Nostr npub and nsec keys
                </p>
                <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 xs:mt-3 text-black">
                  For use with{" "}
                  <a
                    className="w-auto m-auto text-xs font-light text-black underline xs:text-sm sm:text-base dark:text-gray-500"
                    href="https://nostr.com/clients#a-few-of-our-favorite-clients"
                  >
                    Nostr clients
                  </a>
                  . This key is also used to end-to-end encrypt files in
                  Carbonado format.
                </p>
                <div className="flex flex-col w-full">
                  <CopyButton
                    className="w-full"
                    title="npub"
                    darker
                    handleOnClick={() => {
                      navigator.clipboard.writeText(vault.public.nostrNpub);
                      setOpenCopyModal(true);
                    }}
                  >
                    {vault.public.nostrNpub}
                  </CopyButton>
                  <CopyButton
                    className="w-full"
                    title="carbonado npub"
                    darker
                    handleOnClick={() => {
                      navigator.clipboard.writeText(carbonadoNpub);
                      setOpenCopyModal(true);
                    }}
                  >
                    {carbonadoNpub}
                  </CopyButton>
                  <CopyButton
                    className="w-full"
                    title="nsec"
                    darker
                    handleOnClick={() => {
                      navigator.clipboard.writeText(vault.private.nostrNsec);
                      setOpenCopyModal(true);
                    }}
                  >
                    {"*".repeat(vault.private.nostrNsec.length)}
                  </CopyButton>
                </div>
              </div>
              <p className="w-full pt-6 m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
                Update Nostr public key
              </p>
              <form className="flex flex-row pt-3">
                <input
                  className="w-full p-3 text-base placeholder-gray-600 bg-gray-300 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 dark:bg-newdarkmode-700 focus:bg-gray-350 rounded-md focus:outline disabled:opacity-50  disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:text-gray-500"
                  type="text"
                  placeholder="Paste your Nostr public key / npub"
                  onChange={(e) => setNostrPubkey(e.target.value)}
                />
                <RoundedButton
                  className="w-auto max-w-lg m-auto ml-3 text-base text-black bg-yellow-500 disabled:opacity-50"
                  disabled={nostrPubkey === ""}
                  onClick={async () => {
                    let token = "";
                    const tokens = await backend.lightning.auth(
                      vault.public.xpubkh,
                      vault.private.xprvkh
                    );
                    if ("error" in tokens) {
                      console.error("error on ln auth", tokens.error);
                      setNostrPubkey("");
                    } else {
                      token = tokens.token;
                      const { status } = await backend.nostr.updateNostrPubkey(
                        nostrPubkey,
                        token
                      );

                      if (status === "Error: No public key exists for user.") {
                        const result = await backend.nostr.newNostrPubkey(
                          nostrPubkey,
                          token
                        );

                        if (result.status.startsWith("Error")) {
                          console.error(
                            "Error in updating nostr pubkey",
                            result.status
                          );
                        }
                      }

                      setNostrPubkey("");
                    }
                  }}
                >
                  Update
                </RoundedButton>
              </form>
            </div>
          )}
          {selected.name === "User" && (
            <div className="flex flex-col space-y-6">
              {localStorage.getItem("username") && (
                <div className="flex flex-col">
                  <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
                    Username
                  </p>
                  <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
                    Username that can be used with the Lightning Network and
                    Nostr.
                  </p>
                  <CopyButton
                    className="w-full"
                    title="username"
                    hideTitle
                    darker
                    handleOnClick={() => {
                      navigator.clipboard.writeText(
                        localStorage.getItem("username") || ""
                      );
                      setOpenCopyModal(true);
                    }}
                  >
                    {localStorage.getItem("username")}
                  </CopyButton>
                </div>
              )}
              <div className="flex flex-col">
                <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
                  Update username
                </p>
                <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-2 xs:mt-3 text-black">
                  Update username that can be used with the Lightning Network
                  and Nostr.
                </p>
                <form className="flex flex-row mt-3">
                  <input
                    className="w-full p-3 text-base placeholder-gray-600 bg-gray-300 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 dark:bg-newdarkmode-700 focus:bg-gray-350 rounded-md focus:outline disabled:opacity-50  disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:text-gray-500"
                    type="text"
                    placeholder="enter new username"
                    onChange={(e) => {
                      setError("");
                      setUsername(e.target.value);
                    }}
                    value={username}
                  />
                  <RoundedButton
                    className="w-auto max-w-lg m-auto ml-3 text-base text-black bg-yellow-500 disabled:opacity-50"
                    disabled={username === ""}
                    onClick={async () => {
                      const checkUsernameIsUnique = await fetch(
                        `${
                          isExtension ? "https://beta.bitmask.app/" : "/"
                        }checkusername?lookup=${username.toLowerCase()}`
                      );
                      const isUnique = await checkUsernameIsUnique.json();

                      if (isUnique.success === true) {
                        const createUsername = await fetch(
                          `${
                            isExtension ? "https://beta.bitmask.app/" : "/"
                          }username`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              username: username.toLowerCase(),
                              xpubkh: vault.public.xpubkh,
                              xprvkh: vault.private.xprvkh,
                            }),
                          }
                        );
                        const creationSuccess = await createUsername.json();
                        if (creationSuccess.success === true) {
                          window.localStorage.setItem(
                            "username",
                            `${username.toLowerCase()}@bitmask.app`
                          );
                          setUsername("");
                        } else if (creationSuccess.reason) {
                          setError(creationSuccess.reason);
                        } else {
                          setUsername("");
                          setError("Error creating username");
                        }
                      } else {
                        setUsername("");
                        setError("Username already in use");
                      }
                    }}
                  >
                    Update
                  </RoundedButton>
                </form>
              </div>
              {error !== "" && (
                <div className="w-11/12 m-auto xs:w-9/12">
                  <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
                    {error}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </PageWrapper2>
  );
};

export default AccountDetails;
