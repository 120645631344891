/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { getConversion } from "src/Hooks/util";

const WalletSelectDropdown = ({ ...props }) => {
  const [selected, setSelected] = useState({
    name: props.items[0].name,
    address: props.items[0].address,
    balance: props.items[0].balance,
  });
  const [conversion, setConversion] = useState(0);

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  return (
    <Menu as="div" className="relative text-left">
      {({ open }) => (
        <div className="my-4">
          <h3 className="mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
            {props.label}
          </h3>
          <div>
            <Menu.Button className="inline-flex w-full px-4 py-2 text-sm font-medium text-gray-300 rounded-md border-1 border-newdarkmode-600 shadow-sm bg-newdarkmode-700 hover:bg-newdarkmode-700 focus:outline-none focus:ring-1 focus:ring-darkmode-700">
              <div className="flex flex-col">
                <div className="flex mb-2 flex-nowrap">
                  <p className="text-lg font-semibold text-gray-300 truncate">
                    {selected.name}
                  </p>
                  <p className="m-auto ml-2 text-sm font-thin text-gray-500 truncate">
                    ({selected.address})
                  </p>
                </div>
                <div className="flex flex-nowrap">
                  <p className="mr-2 font-semibold text-gray-300 truncate">
                    Balance:{" "}
                  </p>
                  <p className="mr-2 font-semibold text-gray-300 truncate">
                    {selected.balance} tSats
                  </p>
                  <p className="font-semibold text-gray-500 truncate">
                    {((selected.balance / 100_000_000) * conversion).toFixed(3)}{" "}
                    USD
                  </p>
                </div>
              </div>
              <ChevronDownIcon
                className="w-6 h-6 m-auto ml-auto mr-2"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-10 w-full mt-2 shadow-lg origin-top-right rounded-md bg-newdarkmode-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              {props.items.map((item) => (
                <div className="py-1">
                  <Menu.Item
                    as="button"
                    onClick={() => {
                      setSelected({
                        name: item.name,
                        address: item.address,
                        balance: item.balance,
                      });
                      props.setTransaction({
                        ...props.transaction,
                        sender: { name: item.name, address: item.address },
                      });
                    }}
                  >
                    {({ active }) => (
                      <div className="flex flex-col mx-2">
                        <div className="flex mb-1 flex-nowrap">
                          <p className="text-lg font-semibold text-gray-300 truncate">
                            {item.name}
                          </p>
                          <p className="m-auto ml-2 text-sm font-thin text-gray-500 truncate">
                            ({item.address})
                          </p>
                        </div>
                        <div className="flex flex-nowrap">
                          <p className="mr-2 font-semibold text-gray-300 truncate">
                            Balance:{" "}
                          </p>
                          <p className="mr-2 font-semibold text-gray-300 truncate">
                            {item.balance} tSats
                          </p>
                          <p className="font-semibold text-gray-500 truncate">
                            {item.balance} USD
                          </p>
                        </div>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default WalletSelectDropdown;
