import mixpanel from "mixpanel-browser";

// const isProd = process.env.NODE_ENV === "production";
mixpanel.init(
  process.env.REACT_APP_MIXPANEL_TOKEN,
  {
    api_host: "https://api.mixpanel.com",
    debug: true,
  },
  "Bitmask"
);

const Mixpanel = {
  identify: (id) => {
    mixpanel.Bitmask.identify(id);
  },
  alias: (id) => {
    mixpanel.Bitmask.alias(id);
  },
  track: (name, props?) => {
    mixpanel.Bitmask.track(name, props);
  },
  register: (props, days?) => {
    mixpanel.Bitmask.register(props, days);
  },
  register_once: (props, defaultValue?, days?) => {
    mixpanel.Bitmask.register_once(props, defaultValue, days);
  },
  track_links: (query, name) => {
    mixpanel.Bitmask.track_links(query, name, {
      referrer: document.referrer,
    });
  },
  people: {
    set: (props) => {
      mixpanel.Bitmask.people.set(props);
    },
  },
  reset: () => {
    mixpanel.Bitmask.reset();
  },
};

export default Mixpanel;
