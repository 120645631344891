/* global chrome */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as backend from "bitmask-segwit";

import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import { Location } from "src/types";
import { delHash, delay, setHash } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import lib, { isExtension } from "src/lib";
import { defaultLocation } from "src/Components/Navbar/Menu";
import { LockClosedIcon } from "@heroicons/react/24/solid";

const Settings = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  let location = useLocation() as Location;
  const [retrieveSeedPassword, setRetrieveSeedPassword] = useState("");
  const [deleteWalletPassword, setDeleteWalletPassword] = useState("");
  const [walletVault, setVault] = useState<backend.bitcoin.Vault>();
  const [retrieveSeedError, setRetrieveSeedError] = useState(false);
  const [deleteWalletError, setDeleteWalletError] = useState(false);
  const network = window.localStorage.getItem("network");

  useEffect(() => {
    Mixpanel.track("Viewed Settings");
  }, []);

  const handleRetrieveSeed = async () => {
    setRetrieveSeedError(false);
    const encryptedDescriptors = window.localStorage.getItem(
      `${network}Descriptor`
    );
    try {
      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }
      const hash = backend.bitcoin.hashPassword(retrieveSeedPassword);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await setHash(hash, vault.public.xpubkh);
      setVault(vault);
    } catch (err) {
      setRetrieveSeedError(true);
    }
  };

  const handleLockWallet = async () => {
    localStorage.setItem("lockWallet", "true");
    await lib.storageDel(["vault"]);
    delHash();
    window.history.replaceState({}, "", window.location.href);
    location = defaultLocation;
    navigate("/signin", { replace: true });
  };

  const handleDeleteWallet = async () => {
    setDeleteWalletError(false);
    const encryptedDescriptors = window.localStorage.getItem(
      `${network}Descriptor`
    );
    try {
      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }

      const hash = backend.bitcoin.hashPassword(deleteWalletPassword);

      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );

      await setHash(hash, vault.public.xpubkh);

      if (vault) {
        if (isExtension) {
          Mixpanel.reset();
          localStorage.clear();
          lib.storageClear();
          window.history.replaceState({}, "", window.location.href);
          location = defaultLocation;
          await chrome.runtime.sendMessage({ call: "reload_script_and_tabs" });
          await delay(1000);
          chrome.runtime.reload();
        } else {
          Mixpanel.reset();
          localStorage.clear();
          lib.storageClear();
          window.history.replaceState({}, "", window.location.href);
          location = defaultLocation;
          navigate("/");
        }
      }
    } catch (err) {
      setDeleteWalletError(true);
      console.error(err);
    }
  };

  return (
    <PageWrapper2
      className="h-auto"
      title="Settings"
      handlePageBack={() => navigate("/")}
    >
      <div className="flex flex-col justify-center w-full mx-auto xs:p-2 md:w-10/12 sm:bg-gray-200 sm:dark:bggradient sm:rounded-lg sm:shadow-lg pb-9 sm:p-12">
        <div className="flex flex-col w-full space-y-4 xs:space-y-6">
          <div className="flex flex-col">
            <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
              Retrieve seed phrase
            </p>
            <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
              If you never wrote down, or lost your mnemonic seed phrase. you
              can recover it using your password.
            </p>
            {walletVault ? (
              <div className="flex flex-col">
                <div className="w-full m-auto grid grid-cols-3 py-1.5 text-black dark:text-white gap-1.5 sm:gap-3">
                  {walletVault.mnemonic.split(" ").map((phrase, i) => (
                    <div
                      key={phrase}
                      className="border-1 border-gray-400 dark:border-newdarkmode-600 border-opacity-50 text-sm sm:text-base rounded-lg p-1 xs:p-1.5 text-left"
                    >
                      {i + 1}. {phrase}
                    </div>
                  ))}
                </div>
                <RoundedButton
                  className="text-base bg-yellow-500 border-2 border-yellow-500 m-auto text-newdarkmode-800 mt-1.5"
                  onClick={() => {
                    setVault(undefined);
                    setRetrieveSeedPassword("");
                  }}
                >
                  Clear
                </RoundedButton>
              </div>
            ) : (
              <form className="flex flex-row w-full">
                <input
                  type="password"
                  className="w-full p-3 text-base placeholder-gray-600 bg-gray-300 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 dark:bg-newdarkmode-700 focus:bg-gray-350 rounded-md focus:outline disabled:opacity-50  disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:text-gray-500"
                  placeholder="Enter Password"
                  onChange={(e) => {
                    setRetrieveSeedError(false);
                    setRetrieveSeedPassword(e.target.value);
                  }}
                  value={retrieveSeedPassword}
                />
                <RoundedButton
                  className="w-auto max-w-lg m-auto ml-3 text-base text-black bg-yellow-500"
                  onClick={() => handleRetrieveSeed()}
                >
                  Retrieve
                </RoundedButton>
              </form>
            )}
            {retrieveSeedError && (
              <p className="w-full m-auto mt-3 text-sm text-center text-black dark:text-white sm:text-base">
                Error: Incorrect password
              </p>
            )}
          </div>
          <div className="flex flex-col">
            <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
              Lock wallet
            </p>
            <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
              Deletes wallets vault and requires password to recreate sensitive
              data and use functionality.
            </p>
            <RoundedButton
              className="mr-auto text-base bg-yellow-500 border-2 border-yellow-500 text-newdarkmode-800"
              onClick={() => handleLockWallet()}
            >
              <div className="flex flex-row m-auto">
                <LockClosedIcon className="h-5 w-5 mr-1.5 my-auto" />{" "}
                <p className="my-auto">Lock Wallet</p>
              </div>
            </RoundedButton>
          </div>
          <div className="flex flex-col">
            <p className="w-full m-auto text-base font-normal text-left text-black sm:text-lg dark:text-gray-300">
              Remove wallet from browser
            </p>
            <p className="w-full m-auto text-left font-light text-xs xs:text-sm sm:text-base dark:text-gray-500 mt-1.5 mb-3 xs:mt-3 xs:mb-4 text-black">
              Deletes all wallet data from device, this cannot be undone.
              Proceed with caution, you may lose assets or access to wallet if
              data is not properly backed up.
            </p>
            <form className="flex flex-row w-full">
              <input
                type="password"
                className="w-full p-3 text-base placeholder-gray-600 bg-gray-300 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 dark:bg-newdarkmode-700 focus:bg-gray-350 rounded-md focus:outline disabled:opacity-50  disabled:cursor-not-allowed focus:text-gray-900 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:text-gray-500"
                placeholder="Enter Password"
                onChange={(e) => {
                  setDeleteWalletError(false);
                  setDeleteWalletPassword(e.target.value);
                }}
                value={deleteWalletPassword}
              />
              <RoundedButton
                className="w-auto m-auto ml-3 text-base bg-red-600 border-2 border-red-600 text-newdarkmode-900"
                onClick={() => handleDeleteWallet()}
              >
                Delete
              </RoundedButton>
            </form>
            {deleteWalletError && (
              <p className="w-full m-auto mt-3 text-sm text-center text-black dark:text-white sm:text-base">
                Error: Incorrect password
              </p>
            )}
          </div>
        </div>
      </div>
    </PageWrapper2>
  );
};

export default Settings;
