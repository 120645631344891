/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Loader from "src/Components/Loaders/Loader";
import { classNames } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";

const AssetFooter = ({ ...props }) => {
  const navigate = useNavigate();

  return (
    <footer className="flex flex-col w-full h-14">
      <div
        className={classNames(
          !props.tokensWalletFunded || props.loadingVault
            ? "flex justify-center"
            : "grid grid-cols-12 gap-6",
          "h-auto m-auto py-2 text-center w-full px-6 lg:px-12 flex justify-center"
        )}
      >
        {props.loadingVault ? (
          <Loader className="m-auto w-7 h-7" />
        ) : (
          <>
            {props.tokensWalletFunded ? (
              <>
                {props.view === 1 ? (
                  <>
                    <RoundedButton
                      className={classNames(
                        "sm:px-2 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-full col-span-6"
                      )}
                      onClick={() => {
                        Mixpanel.track("Tapped Import Asset");
                        navigate("/importassets", {
                          state: {
                            walletData: props.walletData,
                            vault: props.vault,
                            hash: props.hash,
                            lnCredentials: props.lnCredentials,
                          },
                        });
                      }}
                      lessMargin
                    >
                      <div className="flex flex-row m-auto">
                        <span className="m-auto mr-1.5 text-lg font-bold">
                          +
                        </span>
                        <div className="m-auto text-xs sm:text-sm lg:text-base">
                          Import Assets
                        </div>
                      </div>
                    </RoundedButton>
                    <RoundedButton
                      className={classNames(
                        "sm:px-2 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-full col-span-6"
                      )}
                      onClick={() => {
                        Mixpanel.track("Tapped Create Asset");
                        navigate("/createasset", {
                          state: {
                            walletData: props.walletData,
                            assetUtxos: props.assetUtxos,
                            vault: props.vault,
                            hash: props.hash,
                            lnCredentials: props.lnCredentials,
                          },
                        });
                      }}
                      lessMargin
                    >
                      <div className="flex flex-row m-auto">
                        <span className="m-auto mr-1.5 text-lg font-bold">
                          +
                        </span>
                        <div className="m-auto text-xs sm:text-sm lg:text-base">
                          Create Asset
                        </div>
                      </div>
                    </RoundedButton>
                  </>
                ) : (
                  <>
                    <RoundedButton
                      className="w-full m-auto text-sm text-black border-gray-700 border-1 sm:px-2 col-span-6 dark:border-yellow-500 dark:text-yellow-500"
                      onClick={() => {
                        navigate("/accepttransaction", {
                          state: {
                            walletData: props.walletData,
                            vault: props.vault,
                            hash: props.hash,
                            assetUtxos: props.assetUtxos,
                            lnCredentials: props.lnCredentials,
                            asset: null,
                            type: "UDA",
                            locationHistory: [window.location.hash],
                          },
                        });
                      }}
                      lessMargin
                    >
                      <div className="flex flex-row m-auto">
                        <span className="m-auto mr-1.5 text-lg font-bold">
                          +
                        </span>
                        <div className="m-auto text-xs xs:text-sm lg:text-base">
                          Accept UDA
                        </div>
                      </div>
                    </RoundedButton>
                    <RoundedButton
                      className={classNames(
                        "sm:px-2 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-full col-span-6"
                      )}
                      onClick={() => {
                        // We will need to determine how to decern which asset type we are dealing with
                        // and pass it to receive. Set as Asset for now.
                        navigate("/receive", {
                          state: {
                            walletData: props.walletData,
                            vault: props.vault,
                            assetUtxos: props.assetUtxos,
                            type: "UDA",
                            precision: 0,
                            locationHistory: [window.location.hash],
                            lnCredentials: props.lnCredentials,
                            tokensWalletFunded: props.tokensWalletFunded,
                            tokensWalletAddress: props.tokensWalletAddress,
                            udasWalletAddress: props.udasWalletAddress,
                            hash: props.hash,
                          },
                        });
                      }}
                      lessMargin
                    >
                      <div className="flex flex-row m-auto">
                        <span className="m-auto mr-1.5 text-lg font-bold">
                          +
                        </span>
                        <div className="m-auto text-xs xs:text-sm lg:text-base">
                          Generate Invoice
                        </div>
                      </div>
                    </RoundedButton>
                  </>
                )}
              </>
            ) : (
              <RoundedButton
                className={classNames(
                  "sm:px-2 m-auto text-sm text-black border-1 border-gray-700 dark:border-yellow-500 dark:text-yellow-500 w-auto col-span-12"
                )}
                onClick={() => {
                  props.setOpenFundAssetsModal(true);
                }}
                lessMargin
              >
                <div className="flex flex-row m-auto">
                  <span className="m-auto mr-1.5 text-lg font-bold">+</span>
                  <div className="m-auto">Fund Vault</div>
                </div>
              </RoundedButton>
            )}
          </>
        )}
      </div>
    </footer>
  );
};

export default AssetFooter;
