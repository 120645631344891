/* global chrome */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as backend from "bitmask-segwit";
import { LNDHUBX, Network, init } from "bitmask-segwit/constants";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import Mixpanel from "src/lib/Mixpanel";
import { setHash } from "src/Hooks/util";

const Success = ({ ...props }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  const handleSubmit = async () => {
    try {
      const wallet = "wallet_01";
      await backend.constants.switchNetwork(Network.bitcoin);
      window.localStorage.setItem("network", Network.bitcoin);
      const encryptedDescriptors =
        window.localStorage.getItem("bitcoinDescriptor");
      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }
      const hash = backend.bitcoin.hashPassword(props.password);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await lib.storageSet({ pubKeyHash: vault.public.xpubkh });
      await setHash(hash, vault.public.xpubkh);

      const handleLightningAuth = async (lndhubEndpoint: string) => {
        await init(lndhubEndpoint);

        let refresh = "";
        let token = "";
        if (LNDHUBX) {
          const tokens = await backend.lightning.auth(
            vault.public.xpubkh,
            vault.private.xprvkh
          );

          if ("error" in tokens) {
            setError({
              title: "Error Occurred on LN Wallet Authorization",
              message: tokens.error,
            });
            setOpen(true);
          } else {
            refresh = tokens.refresh;
            token = tokens.token;
            await backend.nostr.newNostrPubkey(vault.public.nostrPub, token);
          }
        }

        Mixpanel.alias(vault.public.xpubkh);
        Mixpanel.track("Created New Wallet", {
          "Wallet ID": vault.public.xpubkh,
        });
        if (isExtension) {
          chrome.runtime.sendMessage({
            call: "reload_script_and_tabs",
          });
        }
        navigate("/wallet", {
          state: {
            wallet,
            vault,
            hash,
            lnCredentials: {
              login: vault.public.xpubkh,
              password: vault.private.xprvkh,
              refreshToken: refresh,
              accessToken: token,
            },
          },
        });
      };

      if (process.env.TEST_LNDHUB_ENDPOINT)
        await handleLightningAuth("testnet");
      if (process.env.PROD_LNDHUB_ENDPOINT)
        await handleLightningAuth("bitcoin");
    } catch (err) {
      console.error("Error in SignIn call to get_encrypted_wallet", err);
      setError({
        title:
          (err as Error)?.name || "Unhandled exception in Success handleSubmit",
        message:
          (err as Error)?.message || err?.toString() || "Unhandled exception",
      });
      setOpen(true);
      if (err?.toString().toLowerCase().includes("invalid")) {
        console.info("Deleting descriptor, parse error");
        window.localStorage.removeItem("descriptor");
      }
    }
  };

  return (
    <div className="w-full h-auto m-auto overflow-hidden">
      <div className="flex flex-col items-center w-full h-full lg:flex-row lg:flex-nowrap">
        <div className="float-left w-full lg:w-6/12">
          <div className="max-w-3xl px-12 mx-auto text-center xs:mt-8 lg:mt-0 lg:mr-0 grid grid-cols-1 sm:px-20 lg:text-left">
            <h1 className="text-3xl font-bold text-gray-900  sm:text:4xl md:text-5xl lg:text-left dark:text-gray-300">
              Congratulations!
            </h1>
            <p className="m-auto my-6 text-base font-light text-left text-gray-900 sm:text-lg md:text-xl dark:text-gray-400">
              You have your very own BitMask wallet! Get ready for all of the
              incredible adventures to come. Get started now by opening the
              BitMask extension on your web browser. Or by using the BitMask Web
              Wallet at{" "}
              <a className="underline" href="https://bitmask.app">
                bitmask.app
              </a>
              .
            </p>

            <RoundedButton
              className="w-7/12 py-3 mx-auto mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl lg:mx-0"
              onClick={() => handleSubmit()}
            >
              Launch
            </RoundedButton>
          </div>
        </div>
        <div className="flex w-full h-full lg:w-6/12">
          <div className="w-full h-full m-auto rounded-full">
            <div className="m-auto">
              <img
                className="w-full h-auto"
                src="/images/creationsuccess.png"
                alt="bitcoin illustration"
              />
            </div>
          </div>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default Success;
