/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as backend from "bitmask-segwit";

import TextArea from "src/Components/Inputs/TextArea";
import ImportAssetTabs from "src/Components/Tabs/ImportAssetTabs";
import Search from "src/Components/Inputs/Search";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import { Asset, Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import { classNames } from "src/Hooks/util";
import Loader from "src/Components/Loaders/Loader";

const ImportAsset = () => {
  const [view, setView] = useState(0);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [contract, setContract] = useState("");
  const [searchResults, setSearchResults] = useState([] as Asset[]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [disabled, setDisabled] = useState({
    rgb: false,
    contract: false,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { walletData, vault, hash, lnCredentials } = location.state;

  useEffect(() => {
    if (view === 1) {
      Mixpanel.track("Viewed Import Asset Search");
    }
    Mixpanel.track("Viewed Import Asset", {
      Page: view === 0 ? "Custom" : "Search",
    });
  }, [view]);

  useEffect(() => {
    if (location.state.contract) setContract(location.state.contract);
    (async () => {
      const key = "listAssets";
      const result = await lib.storageGet<Asset[]>([key]);

      if (result[key]) {
        const futureSearchResults = result[key];
        setSearchResults(futureSearchResults);
      }
    })();
  }, []);

  useEffect(() => {
    if (id !== "") {
      setDisabled({ rgb: false, contract: true });
    } else if (contract !== "") {
      setDisabled({ contract: false, rgb: true });
    } else if (id === "" && contract === "") {
      setDisabled({ contract: false, rgb: false });
    }
  }, [id, contract]);

  const handleSubmit = async (searchId: string | null) => {
    let assetData: backend.rgb.ImportResponse;
    try {
      if (searchId) {
        if (searchId.startsWith("rgb:")) {
          setLoading(true);

          const dbAsset = await fetch(
            `${
              isExtension ? "https://beta.bitmask.app/" : "/"
            }getasset/${searchId}`
          );
          const assetJson = await dbAsset.json();

          assetData = await backend.rgb.importContract(vault.private.nostrPrv, {
            import: "contract",
            data: assetJson.contractGenesis,
          });
        } else {
          setLoading(false);
          setError({
            title: `Error importing asset by ID`,
            message: "Server Error: Invalid RGB ID format",
          });
          setOpen(true);
          return;
        }
        /* } else if (id !== "") {
        if (id.startsWith("")) {
          setLoading(true);
          assetData = await backend.rgb.importContract(vault.private.nostrPrv, {
            import: "contract",
            data: id,
          });
        } else {
          setLoading(false);
          setError({
            title: `Error importing asset`,
            message: "Error: Invalid RGB ID format",
          });
          setOpen(true);
          return;
        } */
      } else if (contract !== "") {
        setLoading(true);
        assetData = await backend.rgb.importContract(vault.private.nostrPrv, {
          import: "contract",
          data: contract,
        });
      } else {
        setLoading(false);
        setError({
          title: `Error importing asset by contract`,
          message: "Error: No asset provided",
        });
        setOpen(true);
        return;
      }

      if (assetData) {
        setLoading(true);
        navigate("/importdescription", {
          state: {
            walletData,
            vault,
            lnCredentials,
            hash,
            asset: assetData,
            source: { view: view === 0 ? "Custom" : "Search", param: search },
          },
        });
      } else {
        console.error("No assetData found");
      }
    } catch (assetError: any) {
      console.error("asset error in ImportResponse", assetError);
      if (assetError.toString().includes("Server error: Incorrect rgb id")) {
        setLoading(false);
        setError({
          title: `Error importing asset`,
          message: "Server error: Invalid RGB ID",
        });
        setOpen(true);
      } else if (assetError.toString().includes("rgbc1")) {
        setLoading(false);
        setError({
          title: "Error importing asset",
          message: "Server error: Invalid RGB contract",
        });
        setOpen(true);
      } else {
        console.error("UNHANDLED ERROR in ImportResponse");
      }
    }
  };

  const addId = async (arg) => {
    setId(arg);
  };

  const tabs = [{ title: "Custom" }, { title: "Search" }];

  return (
    <PageWrapper
      title="Import Asset"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={() => handleSubmit(id)}
      loading={loading}
    >
      <ImportAssetTabs view={view} setView={setView} tabs={tabs} />
      {view === 0 && (
        <div className="h-64 mt-4 text-gray-800 dark:text-gray-500">
          <TextArea
            className="w-full p-2"
            placeholder="Enter asset ID here"
            rows={3}
            onChange={(e) => setId(e.target.value)}
            disabled={disabled.rgb}
          />
          <p className="my-4 text-sm font-normal text-center sm:text-base flex-grow-default">
            or
          </p>
          <TextArea
            className="w-full p-2"
            placeholder="Enter contract"
            rows={3}
            value={contract}
            onChange={(e) => setContract(e.target.value)}
            disabled={disabled.contract}
          />
        </div>
      )}
      {view === 1 && (
        <div className="w-full h-64 my-auto mt-4 dark:border-newdarkmode-600">
          <Link
            to="/registry"
            className="ml-2 text-base font-thin text-black underline dark:text-white"
          >
            View asset registry
          </Link>
          <div className="sticky w-full m-auto">
            <Search
              className="w-full p-2 m-auto"
              search={search}
              setsearch={setSearch}
              disabled
            />
          </div>
          <div className="flex flex-col overflow-y-auto scrollbar dark:darkscrollbar h-44">
            {searchResults.length ? (
              <>
                {searchResults
                  .filter((res) =>
                    res.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((asset) => (
                    <button
                      key={asset.contractId}
                      type="button"
                      className="ml-2"
                      onClick={() => {
                        handleSubmit(asset.contractId);
                      }}
                    >
                      <div className="px-6 py-3 text-base font-semibold text-left text-gray-900 cursor-pointer dark:text-gray-300 dark:hover:text-yellow-500 hover:text-gray-600">
                        {asset.name}
                      </div>
                    </button>
                  ))}
              </>
            ) : (
              <p className="m-auto text-base text-center text-gray-600 lg:text-lg dark:text-gray-300">
                No results at the moment
              </p>
            )}
          </div>
        </div>
      )}
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default ImportAsset;
