/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* global chrome */

import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as backend from "bitmask-segwit";
import { Activity, WalletData } from "bitmask-segwit/bitcoin";
import { LnWalletData } from "bitmask-segwit/lightning";
import { CARBONADO, DISABLE_LN, LNDHUBX, init } from "bitmask-segwit/constants";

import { TEST_IDS } from "tests/ids";

import { MainNavContext } from "src/Hooks/MainNavContext";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Tabs from "src/Components/Tabs/Tabs";
import ArrowUpCircle from "src/Components/Icons/ArrowUpCircle";
import ArrowDownCircle from "src/Components/Icons/ArrowDownCircle";
import ChevronLeft from "src/Components/Icons/ChevronLeft";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Modal from "src/Components/Modals/Modal";
import ReleaseNotes from "src/Components/Modals/Children/ReleaseNotes";
import Loader from "src/Components/Loaders/Loader";
import {
  getConversion,
  classNames,
  addBalance,
  getLargeNumber,
} from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import lib, { isExtension } from "src/lib";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import { Asset, Collection, Location } from "src/types";
import { ifaceTokens, ifaceUdas, releaseNotes } from "src/constants";
import Lightning from "src/Components/Icons/Lightning";
import AssetFooter from "src/Components/Layout/Footers/AssetFooter";
import Banner from "src/Components/Modals/Banner";
import { importWallet } from "src/Pages/WalletImport";
import ErrorModal from "src/Components/Modals/Error";
import { ImportResponse } from "bitmask-segwit/rgb";

const Wallet = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { wallet, vault, lnCredentials, hash } = location?.state ?? {};

  let { network } = location?.state ?? {};

  if (!network) {
    network = localStorage.getItem("network") || "bitcoin";
  }

  const { setMainNav } = useContext(MainNavContext);
  const [loading, setLoading] = useState(false);
  const [loadingBitcoin, setLoadingBitcoin] = useState(false);
  const [loadingLightning, setLoadingLightning] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingUda, setLoadingUda] = useState(false);
  const [loadingVault, setLoadingVault] = useState(true);
  const [tokensWalletAddress, setTokensWalletAddress] = useState("");
  const [tokensWalletFunded, setTokensWalletFunded] = useState(false);
  const [udasWalletAddress, setUdasWalletAddress] = useState("");
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [view, setView] = useState(0);
  const [openVersionPopup, setOpenVersionPopup] = useState(false);
  const [conversion, setConversion] = useState(0);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openBanner, setOpenBanner] = useState(false);
  const [assetUtxos, setAssetUtxos] = useState({
    currentUtxoTokens: "",
    currentUtxoUdas: "",
  });
  const [walletData, setWalletData] = useState<WalletData>(
    localStorage.getItem(`${network}WalletData`)
      ? JSON.parse(localStorage.getItem(`${network}WalletData`) || "")
      : []
  );
  const [lnWalletData, setLnWalletData] = useState<LnWalletData>();
  const [activities, setActivities] = useState<Activity[]>();
  const [assets, setAssets] = useState<Asset[]>(
    localStorage.getItem(`${network}Assets`)
      ? JSON.parse(localStorage.getItem(`${network}Assets`) || "")
      : []
  );
  const [collections, setCollections] = useState<Collection[]>(
    localStorage.getItem(`${network}Udas`)
      ? JSON.parse(localStorage.getItem(`${network}Udas`) || "")
      : []
  );
  const [displayedError, setError] = useState({
    title: "",
    message: "",
  });
  const [openError, setOpenError] = useState(false);
  const [pendingAssetTransfers, setPendingAssetTransfers] = useState(true);
  const [pendingUdaTransfers, setPendingUdaTransfers] = useState(true);

  const ref = useRef<HTMLDivElement>(null);

  const { VERSION, CONTEXT = "" } = process.env;

  const getPage = (param) => {
    switch (param) {
      case 0:
        return "Activities";
      case 1:
        return "Assets";
      case 2:
        return "Udas";
      default:
        return "Activities";
    }
  };

  // Storage accessors
  const walletDataKey = `${network}.wallet.bitcoin`;
  const lnWalletKey = `${network}.LnWalletData`;
  const txHistoryKey = `${network}.TransactionHistory`;
  const assetsKey = `${network}.Assets`;
  const udasKey = `${network}.Udas`;
  const udasWalletDataKey = `${network}.wallet.udas`;

  const storeWalletData = async (newWalletData: WalletData) => {
    setWalletData(newWalletData);
    console.debug("storing Bitcoin wallet data", newWalletData);
    await lib.storageSet({ [walletDataKey]: newWalletData });
    setLoadingBitcoin(false);
  };

  const retrieveWalletData = async (): Promise<WalletData> => {
    const result = (await lib.storageGet<WalletData>([walletDataKey]))[
      walletDataKey
    ];
    if (result) setWalletData(result);
    return result;
  };

  const storeLnWalletData = async (newLnWalletData: LnWalletData) => {
    setLnWalletData(newLnWalletData);
    console.debug("storing LN wallet data", newLnWalletData);
    await lib.storageSet({ [lnWalletKey]: newLnWalletData });
    setLoadingLightning(false);
  };

  const getWalletAddresses = async () => {
    const { address: addresTokens } = await backend.rgb.watcherNextAddress(
      vault.private.nostrPrv,
      "default",
      ifaceTokens
    );
    setTokensWalletAddress(addresTokens);
    const { address: addresUdas } = await backend.rgb.watcherNextAddress(
      vault.private.nostrPrv,
      "default",
      ifaceUdas
    );
    setUdasWalletAddress(addresUdas);
  };

  const retrieveLnWalletData = async (): Promise<LnWalletData> => {
    const result = (await lib.storageGet<LnWalletData>([lnWalletKey]))[
      lnWalletKey
    ];
    if (result) setLnWalletData(result);
    return result;
  };

  const storeActivities = async (newActivities: Activity[]) => {
    setActivities(newActivities);
    console.debug("storing wallet tx data", newActivities);
    await lib.storageSet({
      [txHistoryKey]: newActivities,
    });
    setLoadingActivities(false);
  };

  const retrieveActivities = async (): Promise<Activity[]> => {
    const result = (await lib.storageGet<Activity[]>([txHistoryKey]))[
      txHistoryKey
    ];
    if (result) setActivities(result);
    return result;
  };

  const storeAssets = async (newAssets: Asset[]) => {
    setAssets(newAssets);
    console.debug("storing assets data", newAssets);
    await lib.storageSet({
      [assetsKey]: newAssets,
    });
    setLoadingAssets(false);
  };

  const retrieveAssets = async (): Promise<Asset[]> => {
    const result = (await lib.storageGet<Asset[]>([assetsKey]))[assetsKey];
    if (result) setAssets(result);
    return result;
  };

  const storeUdas = async (newUdas: Collection[]) => {
    setCollections(newUdas);
    console.debug("storing UDAs data", newUdas);
    await lib.storageSet({
      [udasKey]: newUdas,
    });
    setLoadingUda(false);
  };

  const retrieveUdas = async (): Promise<Collection[]> => {
    const result = (await lib.storageGet<Collection[]>([udasKey]))[udasKey];
    if (result) setCollections(result);
    console.log(result, "collections");
    return result;
  };

  const getAssetUtxos = async () => {
    const { utxo: utxoTokens } = await backend.rgb.watcherNextUtxo(
      vault.private.nostrPrv,
      "default",
      ifaceTokens
    );
    const { utxo: utxoUdas } = await backend.rgb.watcherNextUtxo(
      vault.private.nostrPrv,
      "default",
      ifaceUdas
    );
    let currentUtxoTokens = "";
    if (utxoTokens) {
      currentUtxoTokens = utxoTokens.outpoint;
      setLoadingVault(false);
      setTokensWalletFunded(true);
    } else {
      setLoadingVault(false);
    }
    let currentUtxoUdas = "";
    if (utxoUdas) currentUtxoUdas = utxoUdas.outpoint;
    const currentUtxos = {
      currentUtxoTokens,
      currentUtxoUdas,
    };
    setAssetUtxos(currentUtxos);
  };

  // Error handling
  const errorCompensation = async (error: Error, context: string) => {
    console.debug(
      "entering error compensation for",
      error,
      "in context",
      context
    );
    if (
      error?.toString().toLowerCase().includes("invalid network") ||
      error?.toString().toLowerCase().includes("missing field `uid`")
    ) {
      console.debug("compensating by reimporting the wallet");
      try {
        const mainnetDescriptors =
          window.localStorage.getItem("bitcoinDescriptor");
        const newVault = await backend.bitcoin.decryptWallet(
          hash,
          mainnetDescriptors as string
        );

        const isRecoveryState = localStorage.getItem("isRecoveryState");
        localStorage.setItem("isRecoveryState", "in recovery");

        await importWallet({
          hash,
          words: newVault.mnemonic,
          navigate,
          reload: !isRecoveryState,
        });
      } catch (innerError) {
        console.error("failed to compensate", innerError);
        localStorage.removeItem("isRecoveryState");
        setLoading(false);
        setOpenError(true);
        setError({
          title:
            (innerError as Error)?.name ||
            `Unhandled exception in ${context} error compensation`,
          message:
            (innerError as Error)?.message ||
            innerError?.toString() ||
            `Unhandled exception in ${context} error compensation`,
        });
      }
    } else {
      setError({
        title: `Unhandled exception in ${context}`,
        message:
          error?.message ||
          error?.toString() ||
          `Unhandled exception in ${context}`,
      });
      setOpenError(true);
    }
  };

  // LN
  const lnAuth = async () => {
    if (
      lnCredentials?.login?.length > 0 &&
      lnCredentials?.password?.length > 0
    ) {
      const tokens = await backend.lightning.auth(
        lnCredentials.login,
        lnCredentials.password
      );
      return tokens;
    }
    throw new Error("Not logged in");
  };

  const getLightningWalletData = async () => {
    console.debug("getLightningWalletData called");
    try {
      const tokens = await lnAuth();
      let token = "";

      if ("error" in tokens && tokens.error === "UserDoesNotExist") {
        console.warn("Handling UserDoesNotExist in getLightningWalletData...");
        await backend.lightning.createWallet(
          vault.public.xpubkh,
          vault.private.xprvkh
        );
        const retryTokens = await backend.lightning.auth(
          vault.public.xpubkh,
          vault.private.xprvkh
        );
        if ("error" in retryTokens) {
          console.error(
            "Error Authorizing Lightning Wallet",
            retryTokens.error
          );
          setError({
            title: "Error Authorizing Lightning Wallet (retry)",
            message: retryTokens.error,
          });
          setOpenError(true);
          return null;
        }
        token = retryTokens.token;
      } else if ("error" in tokens) {
        console.error(
          "Error Authorizing Lightning Wallet (outer)",
          tokens.error
        );
        setError({
          title: "Error Authorizing Lightning Wallet",
          message: tokens.error,
        });
        setOpenError(true);
        return null;
      } else {
        token = tokens.token;
      }

      const lnAccount = await backend.lightning.getBalance(token);
      const lnTxs = await backend.lightning.getTxs(token);

      console.debug("getLightningWalletData results", { lnAccount, lnTxs });

      const lnActivitiesData = lnTxs.map((transaction) => {
        let date = Math.round(Date.now() / 1000);
        if (transaction.created_at !== null)
          date = Number(transaction.created_at) / 1000;
        const activity: Activity = {
          ...transaction,
          lightning: true,
          date,
          id: transaction.txid,
          assetType: "sats",
          amount:
            (Number(transaction.outbound_amount) - Number(transaction.fees)) *
            100000000,
          fee: Number(transaction.fees) * 100000000,
          action:
            transaction.outbound_account_id === lnAccount[0].account_id
              ? "sent"
              : "received",
          status: "confirmed",
          received: 0,
          sent: 0,
          confirmed: false,
          confirmationTime: {
            height: 0,
            timestamp: 0,
          },
          vsize: 0,
          feeRate: 0,
        };
        return activity;
      });
      await storeLnWalletData({
        balance: lnAccount[0],
        transactions: lnTxs,
      });
      return lnActivitiesData;
    } catch (error) {
      console.error(error, "ln error");
      if (error?.toString().includes("/auth")) {
        try {
          // Error is likely caused by account not present on LNDHubX
          await backend.lightning.createWallet(
            vault.public.xpubkh,
            vault.private.xprvkh
          );
          return getLightningWalletData();
        } catch (err) {
          console.error(
            "error attempting to create an account in getLightningWalletData catch",
            err
          );
          localStorage.setItem("isRecoveryState", "in recovery");
          await errorCompensation(error as Error, "getLightningWalletData");
          return null;
        }
      }
      localStorage.setItem("isRecoveryState", "in recovery");
      await errorCompensation(error as Error, "getLightningWalletData");
      return null;
    }
  };

  // Bitcoin
  const getWalletData = async () => {
    console.debug("getWalletData called");
    setLoadingBitcoin(true);
    setLoadingLightning(true);
    setLoadingActivities(true);
    await init();
    let lnActivities: backend.bitcoin.Activity[] | null = null;
    if (LNDHUBX) {
      lnActivities = await getLightningWalletData();
      console.debug("getLightningWalletData success!");
    } else {
      console.debug(
        "skipping getLightningWalletData due to LNDHUBX being false"
      );
    }
    if (window.localStorage.getItem("loadNetworkChange")) {
      setLoading(true);
    }
    await retrieveWalletData();
    const encryptedDescriptors = window.localStorage.getItem(
      `${network}Descriptor`
    );
    const getWalletDataVault = await backend.bitcoin.decryptWallet(
      hash,
      encryptedDescriptors as string
    );
    const { btcDescriptorXprv, btcChangeDescriptorXprv } =
      getWalletDataVault.private;

    console.debug("getWalletData try block");
    try {
      const response = await backend.bitcoin.getWalletData(
        btcDescriptorXprv,
        btcChangeDescriptorXprv
      );
      const data = { ...response, name: wallet };
      console.debug("getWalletData data", data);
      Mixpanel.register_once({
        "Wallet ID": vault.public.xpubkh,
      });
      Mixpanel.register({
        "Number of Lifetime Transactions": data.transactions.length,
        "Lifetime Transaction Volume": data.transactions
          .map((transaction) => {
            if (transaction.sent === 0) {
              return transaction.received;
            }
            return transaction.sent - transaction.received + transaction.fee;
          })
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ),
      });
      await storeWalletData(data);
      console.debug("Wallet xpubkh:", vault.public.xpubkh);

      const layerOneActivitiesData = data.transactions.map((transaction) => {
        let date = Math.round(Date.now() / 1000);
        if (transaction.confirmationTime !== null)
          date = transaction.confirmationTime.timestamp;
        const activity: Activity = {
          ...transaction,
          date,
          id: transaction.txid,
          assetType: "sats",
          amount:
            transaction.sent === 0
              ? transaction.received
              : transaction.sent - transaction.received - transaction.fee,
          fee: transaction.fee,
          action: transaction.sent === 0 ? "received" : "sent",
          status: transaction.confirmed ? "confirmed" : "pending",
        };
        return activity;
      });
      const sortedActivities = [...layerOneActivitiesData];
      if (lnActivities) {
        sortedActivities.push(...lnActivities);
      }
      sortedActivities.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });

      await storeActivities(sortedActivities);

      if (window.localStorage.getItem("loadNetworkChange") === "true") {
        window.localStorage.removeItem("loadNetworkChange");
      }
      setLoading(false);
      console.debug(`sortedActivities: ${JSON.stringify(sortedActivities)}`);
    } catch (error) {
      await errorCompensation(error as Error, "getWalletData");
    }
  };

  // RGB tokens
  const getNewAssets = async () => {
    setLoadingAssets(true);
    setLoadingUda(true);
    await init();
    if (!CARBONADO) return;
    await retrieveAssets();
    await retrieveUdas();

    try {
      const { contracts } = await backend.rgb.listContracts(
        vault.private.nostrPrv
      );

      const newAssets = contracts.filter(
        (contract) => contract.iface === ifaceTokens
      );

      console.debug("fungibles", newAssets);
      await storeAssets(newAssets);

      const newUdas = contracts.filter(
        (contract) => contract.iface === ifaceUdas && contract.balance > 0
      );

      const udaCollections = {};

      newUdas.forEach((collection) => {
        const { ticker } = collection;

        if (!udaCollections[ticker]) {
          udaCollections[ticker] = [];
        }
        udaCollections[ticker].push(collection);
      });

      const collectionArray: Collection[] = Object.entries(udaCollections).map(
        (ele) => ({
          name: ele[0] === "DIBAUDA" ? "no collection" : ele[0],
          id: ele[0],
          udas: ele[1] as ImportResponse[],
        })
      );

      const newCollections: Collection[] = collectionArray;

      console.debug("udas", newCollections);
      await storeUdas(newCollections);
    } catch (error) {
      await errorCompensation(error as Error, "getNewAssets");
    }
  };

  const getBtcPrice = async () => {
    const rate = await getConversion();
    setConversion(rate);
  };

  const verifyAllTransfers = async () => {
    const { transfers: batchTransfers } = await backend.rgb.verifyTransfers(
      vault.private.nostrPrv
    );
    setPendingAssetTransfers(
      batchTransfers.some((transfer) => {
        if (!transfer.status.block && transfer.iface === ifaceTokens) {
          return true;
        }
        return false;
      })
    );
    setPendingUdaTransfers(
      batchTransfers.some((transfer) => {
        if (!transfer.status.block && transfer.iface === ifaceUdas) {
          return true;
        }
        return false;
      })
    );
  };

  useEffect(() => {
    Mixpanel.track("Viewed Wallet", { Page: getPage(view) });
  }, [view]);

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (isExtension) {
        chrome.storage.local.set({ network });
      }
      const handleSyncWallet = async () => {
        if (
          !location.state ||
          !location.state.vault ||
          !location.state.wallet ||
          !location.state.lnCredentials ||
          !localStorage.getItem(`${network}Descriptor`)
        ) {
          navigate("/", { replace: true });
        } else if (urlParams.get("page")) {
          localStorage.setItem("loggedIn", "true");
          const params = window.location.href
            .split("?")[1]
            .split("#")[0]
            .split("&")
            .map((e) => e.split("=")[1]);
          const handleNavigation = (action) =>
            navigate("/marketplaceaction", {
              replace: true,
              state: {
                message: params[0],
                tabid: parseInt(params[1] as string, 10),
                pubkeyHash: params[2],
                id: params[3],
                uid: params[4],
                vault,
                walletDataUdas: action.data,
                hash,
                marketplaceAction: {
                  title: action.title,
                  description: action.description,
                  call: action.call,
                },
              },
            });
          switch (urlParams.get("page")) {
            case "swap_offer":
              handleNavigation({
                title: "Authorize UDA swap offer",
                description: `Proceed to authorize ${network} UDA swap offer from diba.io`,
                call: "swap_offer",
              });
              break;
            case "swap_bid":
              handleNavigation({
                title: "Authorize UDA swap bid",
                description: `Proceed to authorize ${network} UDA swap bid from diba.io`,
                call: "swap_bid",
              });
              break;
            case "get_invoice":
              handleNavigation({
                title: "Authorize UDA transfer",
                description: `Proceed to authorize ${network} UDA transfer from diba.io`,
                call: "get_invoice",
              });
              break;
            case "issue_uda":
              // eslint-disable-next-line no-case-declarations
              const result = (
                await lib.storageGet<WalletData>([udasWalletDataKey])
              )[udasWalletDataKey];
              handleNavigation({
                title: "Authorize UDA creation",
                description: `Proceed to authorize ${network} UDA creation from diba.io`,
                call: "issue_uda",
                data: result,
              });
              break;
            case "accept_uda":
              handleNavigation({
                title: "Accept UDA transfer",
                description: `Proceed to authorize ${network} UDA accept from diba.io`,
                call: "accept_uda",
              });
              break;
            case "get_vault":
              handleNavigation({
                title: "Request to connect",
                description:
                  "You are about to connect your BitMask wallet to your DIBA account.",
                call: "get_vault",
              });
              break;
            default:
              break;
          }
        } else if (Object.keys(vault).length) {
          localStorage.setItem("loggedIn", "true");
          if (
            !localStorage.getItem(`newVersionInfoPopup${VERSION}${CONTEXT}`)
          ) {
            localStorage.setItem(
              `newVersionInfoPopup${VERSION}${CONTEXT}`,
              "true"
            );
            setOpenVersionPopup(true);
          }
          const walletInfo = await retrieveWalletData();
          const lnWalletInfo = await retrieveLnWalletData();
          const transactionHistory = await retrieveActivities();

          if (walletInfo) {
            await storeWalletData(walletInfo);
          }

          console.debug("debug wallet LN", { lnWalletInfo });

          if (lnWalletInfo && typeof lnWalletInfo === "object")
            storeLnWalletData(lnWalletInfo);

          if (transactionHistory && typeof transactionHistory === "object")
            await storeActivities(transactionHistory);

          await Promise.all([
            getAssetUtxos(),
            getWalletAddresses(),
            getWalletData(),
            getNewAssets(),
            getBtcPrice(),
            verifyAllTransfers(),
          ]);
          if (!localStorage.getItem("showedSeedRecoveryPrompt")) {
            setOpenBanner(true);
          }
          // We are going to verify asset transactions and reload wallet and assets data waiting 1 minute after all operations are do and repeat
          const wait = 30; // in seconds
          setTimeout(async () => {
            await verifyAllTransfers();
            setTimeout(async () => {
              await Promise.all([
                getAssetUtxos(),
                getWalletData(),
                getNewAssets(),
                verifyAllTransfers(),
              ]);
            }, wait * 1000);
          }, wait * 1000);
        } else {
          navigate("/", { replace: true });
        }
      };
      try {
        await handleSyncWallet();
      } catch (error) {
        console.error(error);
        setLoading(false);
        setOpenError(true);
        setError({
          title:
            (error as Error)?.name || "Unhandled exception in handleSyncWallet",
          message:
            (error as Error)?.message ||
            error?.toString() ||
            "Unhandled exception",
        });
      }
    })();
  }, [network]);

  useEffect(() => {
    (async () => {
      if (LNDHUBX) {
        try {
          await lnAuth();
        } catch (e) {
          console.warn("Failed initial LN auth", e);
        }
      }
    })();
  }, [LNDHUBX]);

  useEffect(() => {
    if (setMainNav) setMainNav(true);
  }, []);

  return (
    <div className="flex flex-col w-full mx-auto overflow-x-hidden text-gray-900 h-page dark:text-gray-300 dark:bg-newdarkmode-900 md:dark:bggradientlanding">
      {openBanner && (
        <Banner
          setOpen={setOpenBanner}
          handleClose={() =>
            localStorage.setItem("showedSeedRecoveryPrompt", "true")
          }
        >
          If you haven&#39;t already saved your seed phrase, you can retrieve it
          in settings using your password
        </Banner>
      )}
      <div className="flex w-full h-full overflow-y-auto scrollbar dark:darkscrollbar md:mt-3">
        <div
          className={classNames(
            view === 0
              ? "min-h-[calc(100% - 56px)]"
              : "min-h-[calc((var(--vh) * 100)]",
            "h-auto min-h-full flex flex-col w-full sm:px-6 max-w-4xl mx-auto"
          )}
        >
          <div ref={ref} className="flex flex-col w-full h-auto">
            <div
              className={classNames(
                process.env.CONTEXT === "MIXD Future Factory"
                  ? "hidden"
                  : "flex",
                "w-full flex-nowrap"
              )}
            >
              <div className="flex flex-col w-full pt-4 pb-2 md:flex-row md:flex-nowrap xs:p-6 md:bg-gradient-to-l md:rounded-t-2xl from-gray-300 dark:from-newdarkmode-900 to-transparent">
                <div className="w-11/12 m-auto grid grid-cols-2 md:hidden xs:w-full gap-3">
                  <button
                    type="button"
                    title="Copy wallet address"
                    onClick={() => {
                      navigator.clipboard.writeText(walletData?.address || "");
                      setOpenCopyModal(true);
                      Mixpanel.track("Copied Wallet Address", {
                        Source: "Home Page",
                      });
                    }}
                    className={classNames(
                      localStorage.getItem("username") ? "" : "col-span-2",
                      "rounded-lg shadow-sm px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-newdarkmode-800 dark:bg-opacity-75 font-thin cursor-pointer text-xs text-center w-full m-auto md:hidden flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
                    )}
                    disabled={walletData?.address?.length === 0}
                  >
                    {walletData?.address?.length > 0 ? (
                      <>
                        <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
                          {walletData?.address}
                        </p>
                        <Copy className="flex flex-none w-6 h-6" />{" "}
                      </>
                    ) : (
                      <p className="w-auto m-auto text-center truncate">
                        loading address...
                      </p>
                    )}
                  </button>
                  {localStorage.getItem("username") && (
                    <button
                      type="button"
                      title="Copy username"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          localStorage.getItem("username") || ""
                        );
                        setOpenCopyModal(true);
                        Mixpanel.track("Copied Username", {
                          Source: "Home Page",
                        });
                      }}
                      className="rounded-lg shadow-sm px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-newdarkmode-800 dark:bg-opacity-75 font-thin cursor-pointer text-xs text-center w-full m-auto md:hidden flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
                    >
                      <p className="w-auto mx-auto my-auto text-center truncate">
                        {localStorage.getItem("username")}
                      </p>
                      <Copy className="flex flex-none w-6 h-6" />{" "}
                    </button>
                  )}
                </div>
                <div className="flex flex-col order-2 w-11/12 m-auto flex-grow-default xs:w-full md:w-6/12 md:pr-6 md:ml-0 md:order-1 md:space-y-4">
                  <div className="flex-col hidden w-full m-auto md:flex gap-3">
                    {localStorage.getItem("username") && (
                      <button
                        type="button"
                        title="Copy username"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            localStorage.getItem("username") || ""
                          );
                          setOpenCopyModal(true);
                          Mixpanel.track("Copied Username", {
                            Source: "Home Page",
                          });
                        }}
                        className="rounded-lg shadow-sm px-2 py-0.5 sm:py-1 hidden bg-gray-400 dark:bg-newdarkmode-800 font-thin cursor-pointer text-xs text-center w-full mt-auto md:flex flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
                      >
                        <p className="w-auto mx-auto my-auto text-center truncate">
                          {localStorage.getItem("username")}
                        </p>
                        <Copy className="flex flex-none w-6 h-6" />{" "}
                      </button>
                    )}
                    <button
                      type="button"
                      title="Copy wallet address"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          walletData?.address || ""
                        );
                        setOpenCopyModal(true);
                        Mixpanel.track("Copied Wallet Address", {
                          Source: "Home Page",
                        });
                      }}
                      className={classNames(
                        "rounded-lg shadow-sm px-2 py-0.5 sm:py-1 hidden bg-gray-400 dark:bg-newdarkmode-800 font-thin cursor-pointer text-xs text-center w-full mt-auto md:flex flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
                      )}
                      disabled={walletData?.address?.length === 0}
                    >
                      {walletData?.address?.length > 0 ? (
                        <>
                          <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
                            {walletData?.address}
                          </p>
                          <Copy className="flex flex-none w-6 h-6" />{" "}
                        </>
                      ) : (
                        <p className="w-auto m-auto text-center truncate">
                          loading address...
                        </p>
                      )}
                    </button>
                  </div>
                  <div className="w-full my-auto grid grid-cols-2 gap-4">
                    <RoundedButton
                      className="text-base text-black bg-yellow-500 dark:border-2 dark:border-yellow-500 lg:text-lg"
                      onClick={() => {
                        Mixpanel.track("Tapped Send Bitcoin", {
                          Source: "Main Wallet",
                        });
                        navigate("/send", {
                          state: {
                            locationHistory: [window.location.hash],
                            type: "",
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            asset: {
                              allocations: [],
                              balance: addBalance(walletData?.balance),
                              dollarBalance: (
                                addBalance(walletData?.balance) * conversion
                              ).toFixed(3),
                              id: "",
                              name: "Satoshis",
                              ticker: "Sats",
                            },
                          },
                        });
                      }}
                      lessMargin
                    >
                      Send
                    </RoundedButton>
                    <RoundedButton
                      className="text-base text-black bg-yellow-500 dark:border-2 dark:border-yellow-500 lg:text-lg"
                      onClick={() => {
                        Mixpanel.track("Tapped Receive Bitcoin", {
                          Source: "Main Wallet",
                        });
                        navigate("/receive", {
                          state: {
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            locationHistory: [window.location.hash],
                            tokensWalletFunded,
                            loadingVault,
                            tokensWalletAddress,
                            udasWalletAddress,
                          },
                        });
                      }}
                      lessMargin
                    >
                      Receive
                    </RoundedButton>
                  </div>
                </div>
                <div className="order-1 w-11/12 mx-auto mt-4 mb-5 xs:w-full grid grid-cols-2 md:w-6/12 md:order-2 md:my-0">
                  <div className="flex-col space-y-1.5 sm:space-y-3 justify-center md:mx-auto w-full col-span-2 md:col-span-1 lg:ml-3 bg-gradient-to-l from-blue-500 to-transparent md:bg-none rounded-t-2xl pl-3">
                    <div className="flex flex-nowrap md:flex-col gap-9 md:gap-2">
                      <p className="my-auto text-sm font-normal text-left text-blue-600 lg:text-base lg:font-semibold">
                        Bitcoin (L1)
                      </p>
                      <h1 className="pt-2 mr-6 text-lg font-normal text-right text-black lg:text-xl md:text-left md:mr-0 md:font-semibold dark:text-newdarkmode-900 md:dark:text-gray-300 flex-grow-default md:pt-0">
                        {loadingBitcoin && !walletData.balance ? (
                          <Skeleton
                            baseColor="#2C3447"
                            highlightColor="#333D53"
                            className="opacity-25 max-w-40 md:opacity-100"
                          />
                        ) : (
                          <>
                            {addBalance(walletData?.balance) <= 999_999
                              ? addBalance(walletData?.balance).toLocaleString()
                              : getLargeNumber(
                                  addBalance(walletData?.balance)
                                )}{" "}
                            {network !== "bitcoin" ? "tSats" : "Sats"}
                          </>
                        )}
                      </h1>
                    </div>
                    <div className="flex flex-nowrap md:flex-col gap-9 md:gap-3">
                      <p className="mb-2 text-sm font-light text-left text-black md:my-0 md:flex-grow-default dark:text-gray-300 min-w-30">
                        {loadingBitcoin && !walletData.balance ? (
                          <Skeleton
                            baseColor="#2C3447"
                            highlightColor="#333D53"
                            className="opacity-25 max-w-40 md:opacity-100"
                          />
                        ) : (
                          <>
                            {Number(
                              addBalance(walletData?.balance) / 100_000_000
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 8,
                            })}{" "}
                            {network !== "bitcoin" ? "tBTC" : "BTC"}
                          </>
                        )}
                      </p>
                      <p className="mb-2 ml-auto mr-6 text-sm font-light text-left text-black md:my-0 md:mx-0 md:flex-grow-default dark:text-newdarkmode-900 md:dark:text-gray-300">
                        {loadingBitcoin && !walletData.balance ? (
                          <Skeleton
                            baseColor="#2C3447"
                            highlightColor="#333D53"
                            className="opacity-25 max-w-40 min-w-30 lg:opacity-100"
                          />
                        ) : (
                          <>
                            {Number(
                              (addBalance(walletData?.balance) / 100_000_000) *
                                conversion
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 3,
                            })}{" "}
                            USD
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex-col space-y-1.5 sm:space-y-3 justify-center md:mx-auto w-full col-span-2 md:col-span-1 bg-gradient-to-l from-yellow-500 to-transparent md:bg-none rounded-b-2xl pl-3 mt-1.5 md:mt-0">
                    <div className="flex flex-nowrap md:flex-col gap-9 md:gap-2">
                      <p className="my-auto text-sm font-normal text-left text-yellow-600 lg:text-base lg:font-semibold">
                        Lightning (L2)
                      </p>
                      <h1 className="pt-2 mr-6 text-lg font-normal text-right text-black lg:text-xl md:text-left md:mr-0 md:font-semibold dark:text-newdarkmode-900 md:dark:text-gray-300 flex-grow-default md:pt-0">
                        {loadingLightning && !lnWalletData?.balance.balance ? (
                          <Skeleton
                            baseColor="#2C3447"
                            highlightColor="#333D53"
                            className="opacity-25 max-w-40 md:opacity-100"
                          />
                        ) : (
                          <>
                            {LNDHUBX && !DISABLE_LN && lnWalletData
                              ? Number(lnWalletData.balance.balance) *
                                  100_000_000 <=
                                999_999
                                ? (
                                    Number(lnWalletData.balance.balance) *
                                    100_000_000
                                  ).toLocaleString()
                                : getLargeNumber(
                                    Number(lnWalletData.balance.balance) *
                                      100_000_000
                                  )
                              : "Unavailable"}{" "}
                            {LNDHUBX &&
                              !DISABLE_LN &&
                              lnWalletData &&
                              (network !== "bitcoin" ? "tSats" : "Sats")}
                          </>
                        )}
                      </h1>
                    </div>
                    {LNDHUBX && !DISABLE_LN ? (
                      <div className="flex flex-nowrap md:flex-col gap-9 md:gap-3">
                        <p className="mb-2 text-sm font-light text-left text-black md:my-0 md:flex-grow-default dark:text-gray-300">
                          {loadingLightning &&
                          !lnWalletData?.balance.balance ? (
                            <Skeleton
                              baseColor="#2C3447"
                              highlightColor="#333D53"
                              className="opacity-25 max-w-40 md:opacity-100"
                            />
                          ) : (
                            <>
                              {Number(
                                lnWalletData?.balance.balance
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 8,
                              })}{" "}
                              {network !== "bitcoin" ? "tBTC" : "BTC"}
                            </>
                          )}
                        </p>
                        <p className="mb-2 ml-auto mr-6 text-sm font-light text-left text-black md:my-0 md:mx-0 md:flex-grow-default dark:text-newdarkmode-900 md:dark:text-gray-300">
                          {loadingLightning &&
                          !lnWalletData?.balance.balance ? (
                            <Skeleton
                              baseColor="#2C3447"
                              highlightColor="#333D53"
                              className="opacity-25 max-w-40 md:opacity-100"
                            />
                          ) : (
                            <>
                              {(
                                Number(lnWalletData?.balance.balance) *
                                conversion
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                              })}{" "}
                              USD
                            </>
                          )}
                        </p>
                      </div>
                    ) : (
                      <p className="mb-2 text-sm font-light text-left text-black md:my-0 md:flex-grow-default dark:text-gray-300">
                        {!loading && "Unavailable"}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              view={view}
              setView={setView}
              className=""
              assetsLen={assets.length}
              activitiesLen={activities?.length}
              collectionsLen={collections
                .map((collection) => collection.udas.length)
                .reduce((a, b) => a + b, 0)}
            />
          </div>
          {view === 0 && (
            <>
              {activities?.length ? (
                <div className="w-full h-auto py-3 mx-auto xs:py-4">
                  <div className="flex flex-col w-full gap-3 xs:gap-4 h-auto px-4 xs:px-1.5 mx-auto bg-transparent">
                    {activities?.map((activity) => (
                      <button
                        key={activity.id}
                        className="flex w-full p-2 bg-gray-200 border-gray-300 rounded-lg shadow-sm hover:shadow-lg border-1/2 dark:border-newdarkmode-800 dark:bg-newdarkmode-800 dark:bg-opacity-50 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 flex-nowrap"
                        type="button"
                        onClick={() => {
                          navigate("/viewtransaction", {
                            state: {
                              walletData,
                              assetUtxos,
                              transaction: activity,
                              vault,
                              lnCredentials,
                              hash,
                              conversion,
                              locationHistory: [window.location.hash],
                              tokensWalletFunded,
                              tokensWalletAddress,
                              udasWalletAddress,
                            },
                          });
                        }}
                        data-test-id={TEST_IDS.wallet.activity(activity.id)}
                      >
                        <div className="text-left grid grid-cols-1">
                          <div className="flex flex-row">
                            <div className="mr-1.5">
                              {activity.lightning ? (
                                <Lightning className="w-4 h-4" />
                              ) : (
                                <div className="my-auto text-sm font-semibold text-black dark:text-blue-500">
                                  L1
                                </div>
                              )}
                            </div>
                            <div className="my-auto text-xs font-light text-black dark:text-gray-500 pl-1.5">
                              {new Date(activity.date * 1000).toDateString()}{" "}
                              {new Date(
                                activity.date * 1000
                              ).toLocaleTimeString("en-US", {
                                hour12: true,
                                hour: "numeric",
                                minute: "numeric",
                              })}
                            </div>
                          </div>
                          <div className="flex mt-1 text-base font-semibold text-white flex-nowrap">
                            {activity.action === "sent" ? (
                              <ArrowUpCircle
                                className={classNames(
                                  activity.lightning
                                    ? "text-yellow-500"
                                    : "text-blue-500",
                                  "w-8 h-8 my-auto dark:text-opacity-75"
                                )}
                              />
                            ) : (
                              <ArrowDownCircle
                                className={classNames(
                                  activity.lightning
                                    ? "text-yellow-500"
                                    : "text-blue-500",
                                  "w-8 h-8 my-auto dark:text-opacity-75"
                                )}
                              />
                            )}{" "}
                            <div className="my-auto ml-2 text-base font-normal text-black dark:text-gray-300">
                              {activity.action}{" "}
                              {activity.amount <= 999_999
                                ? activity.amount.toLocaleString()
                                : getLargeNumber(activity.amount)}{" "}
                              {activity.assetType}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col my-auto ml-auto text-right">
                          <span
                            className={classNames(
                              activity.status === "confirmed"
                                ? "dark:text-green-500"
                                : "dark:text-yellow-500",
                              "text-xs font-normal dark:font-light text-black mb-2"
                            )}
                          >
                            {activity.status}
                          </span>
                          <span className="text-xs font-thin text-gray-700 xs:text-sm dark:text-gray-500">
                            {Number(
                              (Number(activity.amount) / 100_000_000) *
                                conversion
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 3,
                            })}{" "}
                            USD
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                  {loadingActivities ? (
                    <Loader className="w-12 h-12 m-auto md:h-20 md:w-20" />
                  ) : (
                    <div className="flex flex-col justify-center w-full h-full">
                      <h3 className="p-6 text-base text-center text-gray-600 sm:text-lg">
                        No activities in wallet
                      </h3>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {view === 1 && (
            <>
              {assets.length ? (
                <div className="h-auto py-3 bg-transparent xs:py-4 flex-grow-default">
                  <div className="flex flex-col w-full h-auto px-4 xs:px-1.5 gap-3 xs:gap-4 mx-auto bg-transparent">
                    {assets.map((asset) => (
                      <button
                        key={asset.contractId}
                        type="button"
                        onClick={() =>
                          navigate("/assetdetails", {
                            state: {
                              walletData,
                              assetUtxos,
                              vault,
                              asset,
                              hash,
                              pendingAssetTransfers,
                              lnCredentials,
                              funded: tokensWalletFunded,
                              tokensWalletAddress,
                              udasWalletAddress,
                              locationHistory: [window.location.hash],
                            },
                          })
                        }
                        onKeyDown={(e) =>
                          e.key === "Enter"
                            ? navigate("/assetdetails", {
                                state: {
                                  walletData,
                                  vault,
                                  pendingAssetTransfers,
                                  asset,
                                  hash,
                                  lnCredentials,
                                  funded: tokensWalletFunded,
                                  tokensWalletAddress,
                                  udasWalletAddress,
                                  locationHistory: [window.location.hash],
                                },
                              })
                            : null
                        }
                        disabled={loadingVault}
                        data-test-id={TEST_IDS.wallet.asset(asset.ticker)}
                        className="w-full p-3 text-gray-600 bg-gray-200 border-gray-300 rounded-lg shadow-sm dark:shadow-md grid grid-cols-2 hover:shadow-lg border-1/2 dark:border-newdarkmode-800 dark:bg-newdarkmode-800 dark:bg-opacity-50 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 dark:text-gray-500 disabled:pointer-events-none"
                      >
                        <div className="w-full mr-auto text-left col-span-1 gap-6">
                          <div className="text-base font-semibold text-gray-900 truncate dark:text-gray-300">
                            {asset.ticker}
                          </div>
                          {/* It's impossible to know the value of an asset without a market.
                            <div className="m-auto text-sm text-black  dark:text-gray-500">
                              {asset.dollarBalance
                                ? `$ ${asset.dollarBalance.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 3,
                                    }
                                  )}USD`
                                : "value unknown"}
                                  </div> */}
                        </div>
                        <div className="flex flex-row w-full my-auto ml-auto col-span-1">
                          <div className="w-auto ml-auto text-base font-normal text-right text-gray-900 truncate flex-shrink-default dark:text-gray-300">
                            {asset.balanceNormalized
                              ? asset.balanceNormalized.toLocaleString()
                              : "0"}{" "}
                            {asset.ticker}
                          </div>
                          <ChevronLeft className="flex-initial w-6 h-6 my-auto ml-3 rotate-180" />
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                  {loadingAssets ? (
                    <Loader className="w-12 h-12 m-auto md:h-20 md:w-20" />
                  ) : (
                    <div className="flex flex-col justify-center w-full h-full">
                      <p className="p-6 pb-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                        No assets in wallet
                      </p>
                      <p className="p-6 pt-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                        Click import assets to add assets to wallet
                      </p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {view === 2 && (
            <>
              {collections &&
              collections.length > 0 &&
              collections[0].udas.length > 0 ? (
                <div className="w-full h-auto py-3 bg-transparent xs:py-4">
                  <div className="flex flex-col w-full h-auto gap-3 xs:gap-4 px-4 xs:px-1.5">
                    {collections.map((collection) => (
                      <Disclosure key={collection.name} as="div" className="">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full px-3 py-1.5 text-left bg-gray-200 border-gray-300 rounded-lg shadow-sm dark:shadow-md hover:shadow-lg border-1/2 dark:border-newdarkmode-800 dark:bg-newdarkmode-800 dark:bg-opacity-50 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 flex-nowrap">
                              {collection.udas[0].meta?.attachments.length &&
                                collection.udas[0].meta?.attachments[0].type ===
                                  "audio/mpeg" && (
                                  <>
                                    {collection.logo ? (
                                      <img
                                        src={collection.logo}
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    ) : (
                                      <img
                                        src="/images/defaultAudioCoverArt.webp"
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    )}
                                  </>
                                )}
                              {collection.udas[0].meta?.attachments.length &&
                                (collection.udas[0].meta?.attachments[0].type.includes(
                                  "image"
                                ) ||
                                  collection.udas[0].meta?.attachments[0]
                                    .type === "a/a") && (
                                  <img
                                    src={
                                      collection.logo
                                        ? collection.logo
                                        : collection.udas[0].meta
                                            ?.attachments[0].source
                                    }
                                    className="object-cover w-10 h-10 m-auto rounded-full"
                                    alt="unique digital asset"
                                  />
                                )}
                              {collection.udas[0].meta?.attachments.length &&
                                collection.udas[0].meta?.attachments[0].type ===
                                  "video/mp4" && (
                                  <>
                                    {collection.logo ? (
                                      <img
                                        src={collection.logo}
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    ) : (
                                      <img
                                        src="/images/defaultAudioCoverArt.webp"
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    )}
                                  </>
                                )}
                              <span className="m-auto ml-2 text-base font-bold truncate flex-grow-default">
                                {collection.name}
                              </span>
                              <span className="pl-3 m-auto ml-auto mr-3 text-sm font-thin text-right text-black dark:text-gray-400 whitespace-nowrap">
                                {collection.udas.length} asset
                                {collection.udas.length > 1 ? "s" : ""}
                              </span>
                              <ChevronUpIcon
                                className={`${
                                  open
                                    ? "transform rotate-180"
                                    : "transform rotate-90"
                                } w-6 h-6 text-gray-600 dark:text-gray-400 m-auto`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <div className="pt-1.5 grid grid-cols-3 xs:pt-4">
                                {collection.udas.map((uda) => (
                                  <button
                                    key={uda.contractId}
                                    type="button"
                                    onClick={() =>
                                      navigate("/uda", {
                                        state: {
                                          walletData,
                                          assetUtxos,
                                          pendingUdaTransfers,
                                          lnCredentials,
                                          vault,
                                          uda,
                                          hash,
                                          locationHistory: [
                                            window.location.hash,
                                          ],
                                        },
                                      })
                                    }
                                  >
                                    {uda.meta?.attachments.length &&
                                      uda.meta?.attachments[0].type ===
                                        "audio/mpeg" &&
                                      !uda.meta?.attachments[1] && (
                                        <img
                                          key={uda.contractId}
                                          src="/images/defaultAudioCoverArt.webp"
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                          alt="unique digital asset"
                                        />
                                      )}
                                    {uda.meta?.attachments.length &&
                                      uda.meta?.attachments[0].type ===
                                        "audio/mpeg" &&
                                      uda.meta?.attachments[1] && (
                                        <img
                                          key={uda.contractId}
                                          src={uda.meta?.attachments[1].source}
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                          alt="unique digital asset"
                                        />
                                      )}
                                    {uda.meta?.attachments.length &&
                                      (uda.meta?.attachments[0].type.includes(
                                        "image"
                                      ) ||
                                        uda.meta?.attachments[0].type ===
                                          "a/a") && (
                                        <img
                                          key={uda.contractId}
                                          src={uda.meta?.attachments[0].source}
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                          alt="unique digital asset"
                                        />
                                      )}
                                    {uda.meta?.attachments.length &&
                                      uda.meta?.attachments[0].type ===
                                        "video/mp4" && (
                                        <video
                                          autoPlay
                                          loop
                                          muted
                                          playsInline
                                          crossOrigin="anonymous"
                                          controlsList="nodownload"
                                          key={uda.contractId}
                                          src={
                                            uda.meta?.attachments.length > 0
                                              ? uda.meta?.attachments[0].source
                                              : ""
                                          }
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                        />
                                      )}
                                  </button>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                  {loadingUda ? (
                    <Loader className="w-12 h-12 m-auto md:h-20 md:w-20" />
                  ) : (
                    <div className="flex flex-col justify-center w-full h-full">
                      <p className="p-6 pb-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                        No Udas in wallet
                      </p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {view !== 0 && (
        <div className="w-full mx-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
          <AssetFooter
            vault={vault}
            walletData={walletData}
            assetUtxos={assetUtxos}
            hash={hash}
            lnCredentials={lnCredentials}
            setOpenFundAssetsModal={setOpenFundAssetsModal}
            tokensWalletFunded={tokensWalletFunded}
            loadingVault={loadingVault}
            view={view}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
          />
        </div>
      )}
      <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
        <FundAssets
          walletBalance={addBalance(walletData?.balance)}
          tokensWalletAddress={tokensWalletAddress}
          udasWalletAddress={udasWalletAddress}
          vault={vault}
          setOpenFundAssetsModal={setOpenFundAssetsModal}
        />
      </Modal>
      <Modal open={openVersionPopup} setOpen={setOpenVersionPopup}>
        <ReleaseNotes version={VERSION} releaseNotes={releaseNotes} />
      </Modal>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={displayedError.message}
        title={displayedError.title}
      />
    </div>
  );
};

export default Wallet;
