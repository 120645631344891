/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as backend from "bitmask-segwit";
import { CARBONADO, LNDHUBX, init } from "bitmask-segwit/constants";

import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import ErrorModal from "src/Components/Modals/Error";
import Mixpanel from "src/lib/Mixpanel";
import lib, { isExtension } from "src/lib";
import { setHash } from "src/Hooks/util";

const SignIn = () => {
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  const network = window.localStorage.getItem("network");

  useEffect(() => {
    if (!localStorage.getItem("bitcoinDescriptor")) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const encryptedDescriptors = window.localStorage.getItem(
        `${network}Descriptor`
      );

      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }

      const hash = backend.bitcoin.hashPassword(password);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await setHash(hash, vault.public.xpubkh);
      if (isExtension) {
        chrome.storage.local.set(
          { pubKeyHash: vault.public.xpubkh },
          async () => {
            console.log("Pubkeyhash stored");
          }
        );
      }
      if (network === "bitcoin" && process.env.PROD_LNDHUB_ENDPOINT) {
        await backend.constants.setEnv(
          "LNDHUB_ENDPOINT",
          process.env.PROD_LNDHUB_ENDPOINT
        );
      } else if (process.env.TEST_LNDHUB_ENDPOINT) {
        await backend.constants.setEnv(
          "LNDHUB_ENDPOINT",
          process.env.TEST_LNDHUB_ENDPOINT
        );
      }
      await init();

      const createWatcher = async (
        nostrKey: string,
        xpub: string
      ): Promise<void> => {
        if (CARBONADO) {
          await backend.rgb.createWatcher(nostrKey, {
            name: "default",
            xpub,
            force: true,
          });
        }
      };

      const reloadScriptAndTabs = () => {
        if (isExtension) {
          chrome.runtime.sendMessage({
            call: "reload_script_and_tabs",
          });
        }
      };

      Mixpanel.identify(vault.public.xpubkh);
      Mixpanel.track("Signed In Wallet", { "Wallet ID": vault.public.xpubkh });

      const handleWalletCreation = async (rf, tkn) => {
        await backend.constants.switchNetwork(
          backend.constants.Network[network as string]
            ? backend.constants.Network[network as string]
            : backend.constants.Network.bitcoin
        );
        window.localStorage.setItem(
          "network",
          backend.constants.Network[network as string]
            ? backend.constants.Network[network as string]
            : backend.constants.Network.bitcoin
        );
        // reloadScriptAndTabs();
        localStorage.removeItem("lockWallet");
        navigate("/wallet", {
          state: {
            wallet: "wallet_01",
            vault,
            hash,
            lnCredentials: {
              login: vault.public.xpubkh,
              password: vault.private.xprvkh,
              refreshToken: rf,
              accessToken: tkn,
            },
          },
        });
      };

      let refresh = "";
      let token = "";
      if (LNDHUBX) {
        const tokens = await backend.lightning.auth(
          vault.public.xpubkh,
          vault.private.xprvkh
        );

        if ("error" in tokens) {
          if (tokens.error === "UserDoesNotExist") {
            await backend.lightning.createWallet(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            const retryTokens = await backend.lightning.auth(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            if ("error" in retryTokens) {
              setError({
                title: "Error Ocurred on Wallet Import",
                message: retryTokens.error,
              });
              setOpen(true);
            } else {
              refresh = retryTokens.refresh;
              token = retryTokens.token;
              handleWalletCreation(refresh, token);
            }
          } else {
            setError({
              title: "Error Ocurred on Wallet Import",
              message: tokens.error,
            });
            setOpen(true);
          }
        } else {
          refresh = tokens.refresh;
          token = tokens.token;
        }
      }
      await createWatcher(vault.private.nostrPrv, vault.public.watcherXpub);
      await handleWalletCreation(refresh, token);
    } catch (err) {
      setError({
        title: `Error signing in`,
        message: "Incorrect password",
      });
      setOpen(true);
    }
  };

  return (
    <div className="h-full m-auto text-gray-900 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12 dark:text-gray-300">
      <div className="flex flex-col justify-center h-full m-auto text-center">
        <div className="h-auto my-auto">
          <h1 className="mt-2 text-3xl font-bold text-center flex-grow-default">
            GM! ⚡
          </h1>
          <p className="my-2 text-sm font-normal text-center text-gray-900 flex-grow-default dark:text-gray-500">
            Welcome to your utility gateway wallet for Bitcoin.
          </p>
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Enter Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
          />
          <RoundedButton
            className="w-10/12 py-3 mx-auto mt-4 text-lg text-black bg-yellow-500 sm:text-base"
            onClick={() => handleSubmit()}
          >
            Sign In
          </RoundedButton>
          <p className="my-4 text-sm font-light text-center text-gray-800 dark:text-gray-500 flex-grow-default">
            or
          </p>
          <p className="mt-2 font-normal text-center text-gray-900 cursor-pointer flex-grow-default mb-14 dark:text-gray-500">
            Load using{" "}
            <button
              type="button"
              onClick={() => {
                Mixpanel.reset();
                localStorage.clear();
                lib.storageClear();
                window.history.replaceState({}, "", window.location.href);
                navigate("/walletimport");
              }}
              className="underline"
            >
              Secret Recovery Phrase
            </button>
          </p>
          <p className="mt-32 font-normal text-center text-gray-900 cursor-pointer dark:text-gray-500">
            Need help?{" "}
            <button
              type="button"
              className="underline"
              onClick={() => {
                const win = window.open(
                  "https://t.me/joinchat/Ajbxchsrj75kMDRi",
                  "_blank"
                );
                win?.focus();
              }}
            >
              Contact bitmask support.
            </button>
          </p>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default SignIn;
