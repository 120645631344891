import React from "react";

const HamburgerMenu = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 19 12"
  >
    <path d="M18.5 8H0.5V6H18.5V8ZM18.5 2H0.5V0H18.5V2Z" />
  </svg>
);

export default HamburgerMenu;
