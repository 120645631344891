/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as backend from "bitmask-segwit";
import { Activity } from "bitmask-segwit/bitcoin";
import { FullIssueRequest, RgbSwapStrategy } from "bitmask-segwit/rgb";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import { Location } from "src/types";
import {
  ifaceUdas,
  sealPrefix,
  terminalBtc,
  terminalUdas,
} from "src/constants";
import { getFeeRate } from "src/Hooks/util";

const MarketplaceActions = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation() as Location;
  const { message, tabid, pubkeyHash, id, uid, marketplaceAction, vault } =
    location.state;
  // TODO: add analytics

  useEffect(() => {
    window.onbeforeunload = () => {
      chrome.runtime.sendMessage({
        call: "focus_last_active_tab",
      });
      chrome.runtime.sendMessage({
        call: "reload_script_and_tabs",
      });
    };
  }, []);

  function uuidv4(): string {
    throw new Error("Function not implemented.");
  }

  return (
    <div className="flex justify-center w-11/12 mx-auto overflow-y-auto h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="flex flex-col justify-center w-full h-full mx-auto content-evenly">
        <h1 className="mt-12 mb-20 text-xl font-normal text-center text-black xs:text-2xl md:text-3xl dark:text-white">
          {marketplaceAction.title}
        </h1>
        <div className="flex flex-col justify-center w-full m-auto">
          <div className="flex flex-row w-full m-auto">
            <div className="flex flex-col justify-center w-full m-auto">
              <img
                src={
                  ![undefined, "undefined"].includes(uid)
                    ? `${process.env.REACT_APP_BUCKET_URI}/${uid}-profile`
                    : "/images/defaultAvatar.png"
                }
                className="order-2 w-12 h-12 m-auto rounded-full"
                alt="profile, or collection"
              />
              <div className="pt-1 pb-6 pl-2 text-base font-light text-center dark:text-gray-300">
                DIBA account
              </div>
            </div>
            <div className="mb-6 border-gray-500 border-dashed border-b-1 w-120" />
            <div className="flex flex-col justify-center w-full m-auto">
              <img
                src="/images/logo_128.png"
                className="order-2 w-12 h-12 m-auto rounded-full"
                alt="profile, or collection"
              />
              <div className="pt-1 pb-6 pl-2 text-base font-light text-center dark:text-gray-300">
                BitMask Wallet
              </div>
            </div>
          </div>
          <div className="pl-2 text-base font-light text-center border-gray-800 pb-9 mt-9 xs:text-lg pt-9 dark:text-gray-300 border-t-1">
            {marketplaceAction.description.includes("diba.io") ? (
              <p>
                {marketplaceAction.description.replace("diba.io", "")}{" "}
                <a
                  href="https://diba.io"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  diba.io
                </a>
                .
              </p>
            ) : (
              marketplaceAction.description
            )}
          </div>
        </div>
        <div className="w-10/12 m-auto mb-12 xs:w-9/12 grid grid-cols-2 gap-6 sm:mb-20">
          <RoundedButton
            className="text-base text-black border-2 border-black-500 dark:border-yellow-500 dark:text-yellow-400"
            onClick={() => {
              chrome.tabs.sendMessage(
                tabid,
                { authenticated: "no", answer: message, pubkeyHash, id },
                (response) => {
                  chrome.runtime
                    .sendMessage({
                      call: "focus_last_active_tab",
                    })
                    .then(() => window.close());
                }
              );
              chrome.runtime
                .sendMessage({
                  call: "focus_last_active_tab",
                })
                .then(() => window.close());
            }}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className="text-base bg-yellow-500 text-newdarkmode-900"
            onClick={async () => {
              try {
                if (marketplaceAction.call === "issue_uda") {
                  setLoading(true);
                  const result = await chrome.storage.local.get(["udaData"]);
                  const { utxo: utxoUdas } = await backend.rgb.watcherNextUtxo(
                    vault.private.nostrPrv,
                    "default",
                    ifaceUdas
                  );
                  let currentUtxoUdas = "";
                  if (utxoUdas) currentUtxoUdas = utxoUdas.outpoint;
                  const newUdaData: FullIssueRequest = {
                    ticker: result.udaData.uda.ticker,
                    name: result.udaData.uda.name,
                    description: result.udaData.uda.description,
                    supply: BigInt(1),
                    precision: 1,
                    seal: `${sealPrefix}:${currentUtxoUdas}`,
                    iface: result.udaData.uda.iface,
                    meta: result.udaData.uda.meta,
                  };
                  const issueResponse = await backend.rgb.fullIssueContract(
                    vault.private.nostrPrv,
                    newUdaData
                  );
                  let swapResponse = {};
                  if (
                    result.udaData.option !== "Unlisted" &&
                    result.udaData.bitcoinPrice &&
                    result.udaData.bitcoinPrice >= 0
                  ) {
                    let strategy: RgbSwapStrategy = RgbSwapStrategy.HotSwap;
                    if (result.udaData.option === "Auction")
                      strategy = RgbSwapStrategy.Auction;
                    swapResponse = await backend.rgb.createOffer(
                      vault.private.nostrPrv,
                      {
                        contractId: issueResponse.contractId,
                        iface: issueResponse.iface,
                        contractAmount: "1",
                        bitcoinPrice: BigInt(result.udaData.bitcoinPrice),
                        descriptor: vault.public.rgbUdasDescriptorXpub,
                        changeTerminal: terminalUdas,
                        bitcoinChanges: [],
                        strategy,
                        expireAt: Date.now() + 24 * 3600 * 1000,
                      }
                    );
                  }
                  chrome.tabs.sendMessage(
                    tabid,
                    {
                      authenticated: "yes",
                      answer: message,
                      pubkeyHash,
                      issueResponse: JSON.stringify(issueResponse),
                      swapResponse: JSON.stringify(swapResponse),
                      id,
                    },
                    (response) => {
                      chrome.runtime
                        .sendMessage({
                          call: "focus_last_active_tab",
                        })
                        .then(() => window.close());
                    }
                  );
                } else if (marketplaceAction.call === "swap_offer") {
                  setLoading(true);
                  const { offerData } = await chrome.storage.local.get([
                    "offerData",
                  ]);
                  let strategy: RgbSwapStrategy = RgbSwapStrategy.HotSwap;
                  if (offerData.status === "onAuction")
                    strategy = RgbSwapStrategy.Auction;
                  const swapResponse = await backend.rgb.createOffer(
                    vault.private.nostrPrv,
                    {
                      contractId: offerData.contractId,
                      iface: offerData.iface,
                      contractAmount: "1",
                      bitcoinPrice: offerData.offer.bitcoinPrice,
                      descriptor: vault.public.rgbUdasDescriptorXpub,
                      changeTerminal: terminalUdas,
                      bitcoinChanges: [],
                      strategy,
                    }
                  );
                  chrome.tabs.sendMessage(
                    tabid,
                    {
                      authenticated: "yes",
                      answer: message,
                      pubkeyHash,
                      swapResponse: JSON.stringify(swapResponse),
                      id,
                    },
                    (response) => {
                      chrome.runtime
                        .sendMessage({
                          call: "focus_last_active_tab",
                        })
                        .then(() => window.close());
                    }
                  );
                } else if (marketplaceAction.call === "swap_bid") {
                  setLoading(true);
                  const { transfers: batchTransfers } =
                    await backend.rgb.verifyTransfers(vault.private.nostrPrv);
                  const pendingUdaTransfers = batchTransfers.some(
                    (transfer) => {
                      if (
                        !transfer.status.block &&
                        transfer.iface === ifaceUdas
                      ) {
                        return true;
                      }
                      return false;
                    }
                  );
                  if (pendingUdaTransfers) {
                    // TODO: error meessage for user
                    chrome.tabs.sendMessage(
                      tabid,
                      {
                        authenticated: "yes",
                        answer: message,
                        pubkeyHash,
                        error: "pending transactions",
                        id,
                      },
                      (response) => {
                        chrome.runtime
                          .sendMessage({
                            call: "focus_last_active_tab",
                          })
                          .then(() => window.close());
                      }
                    );
                  } else {
                    const { bidData } = await chrome.storage.local.get([
                      "bidData",
                    ]);
                    const transaction: Activity = {
                      date: Date.now(),
                      id: uuidv4(),
                      action: "sent",
                      status: "pending",
                      amount: 1,
                      assetType: "UDA",
                      fee: 1000, // TODO: implement fee
                      sender: {
                        name: "", // TODO: walletData.name
                        address: "", // TODO: walletData.address
                      },
                      recipient: {
                        name: "",
                        address: "",
                        invoice: "",
                      },
                      txid: "",
                      received: 0,
                      sent: 0,
                      confirmed: false,
                      confirmationTime: {
                        height: 0,
                        timestamp: 0,
                      },
                      vsize: 0,
                      feeRate: 0,
                    };
                    const feeRate = await getFeeRate();
                    const swapResponse = await backend.rgb.directSwap(
                      vault.private.nostrPrv,
                      {
                        offerId: bidData.offerId,
                        assetAmount: "1",
                        descriptor: vault.public.btcDescriptorXpub,
                        changeTerminal: terminalBtc,
                        fee: { feeRate },
                      }
                    );
                    chrome.tabs.sendMessage(
                      tabid,
                      {
                        authenticated: "yes",
                        answer: message,
                        pubkeyHash,
                        error: "no",
                        swapResponse: JSON.stringify(swapResponse),
                        transaction: JSON.stringify(transaction),
                        id,
                      },
                      (response) => {
                        chrome.runtime
                          .sendMessage({
                            call: "focus_last_active_tab",
                          })
                          .then(() => window.close());
                      }
                    );
                  }
                } else {
                  chrome.tabs.sendMessage(
                    tabid,
                    { authenticated: "yes", answer: message, pubkeyHash, id },
                    (response) => {
                      chrome.runtime
                        .sendMessage({
                          call: "focus_last_active_tab",
                        })
                        .then(() => window.close());
                    }
                  );
                }
              } catch (err) {
                chrome.tabs.sendMessage(
                  tabid,
                  {
                    authenticated: "yes",
                    answer: message,
                    pubkeyHash,
                    id,
                    error: err,
                  },
                  (response) => {
                    chrome.runtime
                      .sendMessage({
                        call: "focus_last_active_tab",
                      })
                      .then(() => window.close());
                  }
                );
              }
            }}
            loading={loading}
          >
            Authorize
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceActions;
