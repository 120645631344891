/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import { updatingAssets } from "src/aux/updateAssets";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";

const ImportAssetConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    vault,
    lnCredentials,
    asset: assetData,
    source,
    hash,
  } = location.state;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Mixpanel.track("Viewed Import Confirmation", {
      Asset: assetData,
      Source: source.view,
    });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const futureAssets = await updatingAssets(walletData, assetData);
    console.debug(`futureAssets: ${futureAssets}`);
    Mixpanel.track("Imported Asset", {
      Asset: assetData,
      Source: source.view,
    });
    navigate("/wallet", {
      state: { wallet: walletData.name, vault, hash, lnCredentials },
    });
  };

  return (
    <PageWrapper
      title="Import Asset Confirmation"
      handlePageBack={() =>
        navigate("/importdescription", {
          state: {
            walletData,
            vault,
            lnCredentials,
            source,
            asset: assetData,
            hash,
          },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={() => handleSubmit()}
      loading={loading}
    >
      <div className="w-full mt-4 sm:bg-gray-300 sm:dark:bggradient sm:rounded-lg sm:shadow-lg sm:p-12">
        <div className="flex w-auto mx-6 flex-nowrap">
          <h3 className="w-full text-lg font-semibold text-left text-black dark:text-gray-300">
            Asset
          </h3>
          <h3 className="w-full text-lg font-semibold text-center text-black dark:text-gray-300">
            amount
          </h3>
        </div>
        <div className="flex flex-row w-full mt-2 border-gray-300 border-t-1/2 dark:border-gray-500">
          <h3 className="w-full mt-4 ml-6 text-xl font-normal text-left text-gray-900 dark:text-gray-300">
            {assetData.ticker}
          </h3>
          <h3 className="w-full mt-4 mr-6 text-xl font-normal text-center text-gray-900 dark:text-gray-300">
            {assetData.balance.toLocaleString()}
          </h3>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ImportAssetConfirm;
