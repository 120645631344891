/* eslint-disable arrow-body-style */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Modal = ({ ...props }) => {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={props.open}
        onClose={props.setOpen}
      >
        <div className="flex items-center justify-center min-h-screen text-center lg:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="dark:darkscrollbar z-40 inline-block h-auto px-3 py-3 mx-3 overflow-x-hidden overflow-y-auto max-h-[90vh] text-left text-black align-middle bg-gray-100 shadow-xl sm:px-6 md:px-12 sm:py-6 w-11/12 sm:max-w-11/12 sm:w-auto md:max-w-10/12 xs:min-w-112 lg:min-w-[36rem] dark:bggradientlanding dark:text-gray-600 rounded-xl transform transition-all">
              {!props.blocking && (
                <div className="absolute top-0 right-0 block pt-1 pr-1 md:pt-2 md:pr-4">
                  <button
                    type="button"
                    className="m-3 text-black rounded-md dark:text-white dark:hover:text-gray-300"
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-7 w-7 md:w-9 md:h-9"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              )}
              <div className="">
                <div className="mt-3 text-center">
                  <div className="m-auto">{props.children}</div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
