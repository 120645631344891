/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* global chrome */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import ChevronLeft from "src/Components/Icons/ChevronLeft";
import Trash from "src/Components/Icons/Trash";
import { DeleteAsset } from "src/Components/Modals/DeleteAsset";
import ErrorModal from "src/Components/Modals/Error";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import Modal from "src/Components/Modals/Modal";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import { addBalance } from "src/Hooks/util";
import CopyButton from "src/Components/Buttons/CopyButton";
import DefaultMenu from "src/Components/Menus/Menu";
import Dots from "src/Components/Icons/Dots";
import { isExtension } from "src/lib";
import { rgb } from "bitmask-segwit";

const AssetDetails = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    lnCredentials,
    vault,
    asset,
    assetUtxos,
    funded,
    locationHistory,
    tokensWalletAddress,
    udasWalletAddress,
    pendingAssetTransfers,
    hash,
  } = location.state;
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [openCopyModal, setOpenCopyModal] = useState(false);

  useEffect(() => {
    Mixpanel.track("Viewed Asset Details", { Asset: asset });
  }, []);

  return (
    <div className="flex justify-center w-11/12 h-auto mx-auto overflow-hidden min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="flex flex-col w-full mx-auto content-evenly">
        <div className="flex flex-row w-full h-auto mx-auto content-evenly">
          <button
            type="button"
            className="flex flex-row mt-3 mb-3 text-black cursor-pointer lg:mt-6 flex-nowrap dark:text-white dark:hover:text-gray-300 hover:text-gray-700"
            onClick={() =>
              navigate("/wallet", {
                state: { wallet: walletData.name, vault, hash, lnCredentials },
              })
            }
          >
            <ChevronLeft className="w-8 h-8 m-auto font-bold" />
            <h1 className="m-auto ml-3 text-2xl font-medium text-left flex-grow-default">
              Asset Details
            </h1>
          </button>
          <button
            type="button"
            onClick={() => {
              Mixpanel.track("Tapped Delete Asset");
              setOpen(true);
            }}
            className="my-auto ml-auto mr-3 disabled:opacity-50 disabled:pointer-events-none"
            disabled
          >
            <Trash className="w-8 h-8 text-gray-800 dark:text-gray-300 dark:hover:text-gray-500 hover:text-gray-600 margin-auto" />
          </button>
        </div>
        <div className="flex flex-col justify-center w-11/12 h-auto m-auto xs:w-9/12 flex-grow-default">
          <div className="w-full px-6 pb-6 m-auto text-black bg-gray-300 rounded-lg shadow-lg dark:text-gray-400 dark:bggradient sm:px-12 sm:pb-12">
            <DefaultMenu
              originBottom
              title="Asset Options"
              items={[
                {
                  name: "Publish Contract",
                  onClick: async () => {
                    const network = window.localStorage.getItem("network");
                    if (network === "bitcoin") {
                      await fetch(
                        `${
                          isExtension ? "https://beta.bitmask.app/" : "/"
                        }createasset`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(
                            {
                              asset: {
                                creatorXpubkh: vault.public.xpubkh,
                                ticker: asset.ticker,
                                name: asset.name,
                                description: asset.description,
                                supply: asset.supply,
                                precision: asset.precision,
                                contractId: asset.contractId,
                                contractGenesis: asset.contract.armored,
                              },
                              auth: {
                                xpubkh: vault.public.xpubkh,
                                xprvkh: vault.private.xprvkh,
                              },
                            },
                            (key, value) =>
                              typeof value === "bigint"
                                ? value.toString()
                                : value
                          ),
                        }
                      );
                    }
                  },
                },
              ]}
              className="absolute right-0 pb-4 top-6 w-7 ml-auto mr-0"
            >
              <Dots className="my-auto text-gray-800 h-7 w-7 md:h-8 md:w-8 dark:text-gray-400" />
            </DefaultMenu>
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                Asset Ticker:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {asset.ticker}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                Asset Name:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {asset.name}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                Supply:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {rgb
                  .contractAmountParseStr(
                    asset.supply.toString(),
                    asset.precision
                  )
                  .toLocaleString()}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                Precision:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {asset.precision.toLocaleString()}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                Holdings:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {asset.balanceNormalized
                  ? asset.balanceNormalized?.toLocaleString()
                  : "0"}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                % of Supply:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {(
                  (Number(asset.supply) === 0
                    ? 0
                    : Number(asset.balanceNormalized) /
                      Number(
                        rgb.contractAmountParseStr(
                          asset.supply.toString(),
                          asset.precision
                        )
                      )) * 100
                ).toLocaleString()}
                %
              </p>
            </div>
          </div>
        </div>
        <CopyButton
          className="w-11/12 m-auto xs:w-9/12"
          title="asset id"
          handleOnClick={() => {
            navigator.clipboard.writeText(asset.contractId);
            setOpenCopyModal(true);
          }}
        >
          {asset.contractId}
        </CopyButton>
        <CopyButton
          className="w-11/12 m-auto xs:w-9/12"
          title="asset contract"
          handleOnClick={() => {
            navigator.clipboard.writeText(asset.contract.armored);
            setOpenCopyModal(true);
          }}
        >
          {asset.contract.armored}
        </CopyButton>
        <div className="w-11/12 py-6 mx-auto text-xl font-semibold xs:w-9/12 sm:pb-12 lg:pb-20">
          <div className="w-full m-auto xs:mt-6 grid grid-cols-2">
            <RoundedButton
              className="text-base text-black bg-yellow-500 disabled:bg-opacity-50"
              title={
                pendingAssetTransfers
                  ? "Pending asset transfers. Transfers temporarily unavailable."
                  : "send asset"
              }
              disabled={pendingAssetTransfers}
              onClick={() => {
                Mixpanel.track("Tapped Send Asset");
                navigate("/send", {
                  state: {
                    ...location.state,
                    locationHistory: [...locationHistory, window.location.hash],
                    type: "Asset",
                  },
                });
              }}
            >
              Send
            </RoundedButton>
            <RoundedButton
              className="ml-2 text-base text-black bg-yellow-500"
              onClick={() => {
                Mixpanel.track("Tapped Accept Asset");
                navigate("/accepttransaction", {
                  state: {
                    ...location.state,
                    type: "Asset",
                    locationHistory: [...locationHistory, window.location.hash],
                  },
                });
              }}
            >
              Accept
            </RoundedButton>
          </div>
          <RoundedButton
            className="w-full m-auto mt-4 text-base text-black bg-yellow-500"
            onClick={() => {
              if (funded) {
                Mixpanel.track("Tapped Generate Asset Invoice", {
                  Asset: asset,
                });
                // We will need to determine how to decern which asset type we are dealing with
                // and pass it to receive. Set as Asset for now.
                navigate("/receive", {
                  state: {
                    ...location.state,
                    assetUtxos,
                    type: "Asset",
                    precision: asset.precision,
                    locationHistory: [...locationHistory, window.location.hash],
                    contractId: asset.contractId,
                  },
                });
              } else {
                Mixpanel.track("Tapped Fund Vault");
                // If we don't have the necessary utxas in assets wallet, we say the user tu fund it
                setOpenFundAssetsModal(true);
              }
            }}
          >
            {funded ? "Generate Invoice" : "Fund assets wallet"}
          </RoundedButton>
        </div>
      </div>
      <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
        <FundAssets
          walletBalance={addBalance(walletData?.balance)}
          vault={vault}
          udasWalletAddress={udasWalletAddress}
          tokensWalletAddress={tokensWalletAddress}
          setOpenFundAssetsModal={setOpenFundAssetsModal}
        />
      </Modal>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      <DeleteAsset
        open={open}
        setOpen={setOpen}
        walletData={walletData}
        assetData={asset}
        lnCredentials={lnCredentials}
        hash={hash}
      />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default AssetDetails;
