import React, { useRef, useEffect } from "react";

import { classNames, omitKeys } from "src/Hooks/util";

const FullWidthTextField = ({ className = "", ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.focus) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <div className="flex flex-col justify-center my-1.5 sm:my-4">
      {props.label && (
        <h3 className="mb-1 text-sm font-semibold text-left text-gray-800 dark:text-gray-500">
          {props.label}
        </h3>
      )}
      <input
        className={classNames(
          className,
          "bg-gray-300 focus:bg-gray-350 rounded-md text-base focus:outline disabled:opacity-50 disabled:cursor-not-allowed mx-auto focus:text-gray-900 placeholder-gray-600 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-500 focus:ring-1 focus:ring-gray-700 focus:border-gray-700 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 text-base",
          props.darkest ? "dark:bg-newdarkmode-700" : "dark:bg-newdarkmode-700"
        )}
        ref={inputRef}
        {...omitKeys(props, ["darkest"])}
      />
    </div>
  );
};

export default FullWidthTextField;
