import React from "react";

const LogoDark = ({ className = "" }) => (
  // TODO: fix svg in safari
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
    viewBox="0 0 825.21 182.67"
  >
    <path
      fill="currentColor"
      d="M244.42,174.13c0,18.33-12.91,32.19-48.63,32.19H127.07V92.23h70.62c34.5,0,43.74,15.21,43.74,30.29,0,12.9-6.66,18.47-14.67,22.81v2.18C237.76,153.08,244.42,161.09,244.42,174.13ZM164.69,121v17.12h29.07c8.82,0,11.68-4.35,11.68-8.42,0-4.35-2.86-8.7-11.68-8.7Zm41.7,46.86c0-4.35-3-9.24-11.82-9.24H164.69v18.88h29.88C203.4,177.52,206.39,172.09,206.39,167.88Z"
      transform="translate(-127.07 -58.67)"
    />
    <path
      fill="currentColor"
      d="M255.56,92.23h37.62V206.32H255.56Z"
      transform="translate(-127.07 -58.67)"
    />
    <path
      fill="currentColor"
      d="M421.66,123.6h-41v82.72H343V123.6H302V92.23H421.66Z"
      transform="translate(-127.07 -58.67)"
    />
    <path
      fill="currentColor"
      d="M430.48,92.23h47.4l18.61,65.06h2.71l18.61-65.06h47.4V206.32H529.36V155.79h-3l-14.53,50.53h-28l-14.54-50.53h-3v50.53H430.48Z"
      transform="translate(-127.07 -58.67)"
    />
    <path
      fill="currentColor"
      d="M663.4,191.24H614.51l-5,15.08H570.64l42-114.09H665.3l42,114.09H668.43Zm-9.23-27.84-11.95-35.86H635.7l-12,35.86Z"
      transform="translate(-127.07 -58.67)"
    />
    <path
      fill="currentColor"
      d="M705.23,168.15h33.82c.4,7.74,6.92,12.5,23.22,12.63,14.4.14,21.05-3.94,21.05-9.23,0-3.67-2-7.47-13.17-9.1l-14.67-2.31C733.89,156.61,708,154,708,124.83c0-22.55,18.33-36.4,54.59-36.4,32.74,0,55.55,9.1,54.88,41.69H783.87c-1.36-7.6-7.74-12.36-22.14-12.36-12.5,0-17.66,3.81-17.66,9,0,3.26,2,7.2,10.73,8.55l12.09,2c22.68,3.8,53.79,4.48,53.79,35.72,0,24.86-19.29,37.22-58.41,37.08C722.88,210,704.28,197.76,705.23,168.15Z"
      transform="translate(-127.07 -58.67)"
    />
    <path
      fill="currentColor"
      d="M913.84,206.32l-30.42-40.61H881l-15.62,16.7v23.91H827.87V92.23h37.48v45.23h2.45L909,92.23h41.83L904.47,143l47.81,63.29Z"
      transform="translate(-127.07 -58.67)"
    />
    <polygon
      fill="#ffcf03"
      points="468.78 110.39 512.59 110.39 486.94 182.67 540.04 133.19 523.97 96.11 561.72 0 468.78 110.39"
    />
  </svg>
);

export default LogoDark;
