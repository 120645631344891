import React from "react";
import { classNames } from "src/Hooks/util";
import Copy from "../Icons/Copy";

const CopyButton = ({ className = "", ...props }) => (
  <div className={`${className} flex flex-col w-full`}>
    {props.title && !props.hideTitle && (
      <h2 className="mt-3 mb-3 text-base font-normal text-gray-900 sm:mt-6 xs:sm:text-lg dark:text-gray-500">
        {props.title}
      </h2>
    )}
    <button
      type="button"
      className={classNames(
        "w-auto max-w-full py-1 xs:py-1.5 my-auto text-gray-800 bg-gray-300 rounded-lg cursor-pointer hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500",
        props.darker ? "dark:bg-newdarkmode-800" : "dark:bggradient"
      )}
      onClick={() => props.handleOnClick()}
      title={`Copy ${props.title}`}
    >
      <div className="flex justify-center px-3 flex-nowrap sm:px-6">
        <p className="w-full my-auto text-base font-light truncate">
          {props.children}
        </p>
        <Copy className="my-auto ml-3 w-7 h-7 sm:h-9 sm:w-9 sm:ml-6" />
      </div>
    </button>
  </div>
);

export default CopyButton;
