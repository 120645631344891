/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { motion } from "framer-motion";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import Loader from "src/Components/Loaders/Loader";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import useWindowSize from "src/Hooks/useWindowSize";
import LineChart from "src/Components/Charts/LineChart";

const Metrics = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [animation, setAnimation] = useState(true);
  const [metricData, setMetricData] = useState<any>({
    previous: null,
    current: null,
  });
  const [loggedIn, setLoggedIn] = useState(true);
  const network = window.localStorage.getItem("network");
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [bgColor, setBgColor] = useState<any>(undefined);

  const isPopup = window.location.href.split("?").pop()?.includes("popup");

  const handleRedirectToFullscreenTab = () => {
    window.close();
    const win = window.open(
      `${window.location.href.split("?")[0]}/#/metrics`,
      "_blank"
    );
    win?.focus();
  };

  const snakeToUpperCase = (input) =>
    input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  useEffect(() => {
    if (
      isPopup &&
      (window.location.href.split("/").pop() === "index.html" ||
        window.location.href.split("/").pop() === "metrics")
    ) {
      handleRedirectToFullscreenTab();
    }
  }, []);

  useEffect(() => {
    const getMonthlyData = (dataPoints) => {
      const labels = Object.keys(dataPoints).filter(
        (date, index, array) => index === 0 || new Date(date).getDate() === 1
      );
      const d = labels.map((label) => dataPoints[label]);
      return [labels, d];
    };
    const getWeeksInRange = (startDate: string, endDate: string): number => {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const timeDifference = end.getTime() - start.getTime();

      const weeks = Math.ceil(timeDifference / (7 * 24 * 60 * 60 * 1000));

      return weeks;
    };
    const getEvenlySpacedData = (
      dataPoints: Record<string, number>,
      numberOfPoints = 35
    ): [string[], number[]] => {
      const labels = Object.keys(dataPoints);
      const totalDataPoints = labels.length;

      const step = Math.floor(totalDataPoints / numberOfPoints);
      const evenlySpacedLabels: string[] = [];

      for (let i = 0; i < numberOfPoints; i += 1) {
        const index = i * step;
        evenlySpacedLabels.push(labels[index]);
      }

      const d = evenlySpacedLabels.map((label) => dataPoints[label]);
      return [evenlySpacedLabels, d];
    };
    const getMetricData = async (loader) => {
      if (loader) setLoading(true);
      const data = await fetch(
        "https://hut8-ca-on-mississauga.carbonado.io/bit/metrics.json",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await data.json();
      const respArray = Object.entries(response.bytes_by_day);

      const handleUpdate = (prev, current) => {
        if (prev !== current) {
          return true;
        }
        return false;
      };

      const updateState = (newState) => {
        setMetricData((prevState) => {
          const stats = [
            ...Object.entries(response.wallets_by_network).map((item, i) => ({
              name: item[0],
              value: item[1],
              update: prevState.current?.stats[i]?.value
                ? handleUpdate(prevState.current?.stats[i]?.value, item[1])
                : undefined,
            })),
            {
              name: "Total Bytes",
              value: response.bytes,
              update: prevState.current?.stats?.[7]?.value
                ? handleUpdate(
                    prevState.current?.stats?.[7].value,
                    response.bytes
                  )
                : undefined,
            },
          ];
          return {
            current: { ...newState, stats },
            previous: prevState.current,
          };
        });
      };
      if (response && loader) setLoading(false);
      updateState({
        bitcoin: getEvenlySpacedData(response.bitcoin_wallets_by_day),
        testnet: getEvenlySpacedData(response.testnet_wallets_by_day),
        signet: getEvenlySpacedData(response.signet_wallets_by_day),
        regtest: getEvenlySpacedData(response.regtest_wallets_by_day),
        bytes: getEvenlySpacedData(response.bytes_by_day),
        bytesByMonth: response.bytes_by_day,
        weeksInDataSet: getWeeksInRange(
          respArray[0][0],
          respArray[respArray.length - 1][0]
        ),
      });
    };
    const loadMetricData = async (loader) => {
      setTimeout(async () => {
        setAnimation(true);
        setLoggedIn(false);
        if (localStorage.getItem(`${network}Descriptor`)) {
          setLoggedIn(true);
          await getMetricData(loader);
        } else {
          await getMetricData(loader);
        }
      }, 1000);
    };
    loadMetricData(true);
    const interval = setInterval(() => {
      setAnimation(false);
      loadMetricData(false);
      // if () clearInterval(interval);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const isDataUpdated = () => {
      if (metricData.previous) {
        if (Object.keys(metricData.previous).length && metricData.current) {
          return (
            metricData.previous.stats[7].value !==
            metricData.current.stats[7].value
          );
        }
      }

      return undefined;
    };
    if (isDataUpdated() && bgColor === undefined) {
      setBgColor(["#A2F936", "#2C3447"]);
    }
  }, [metricData]);

  const shouldUpdate = (update, data, i) => {
    if (update && data !== metricData.previous.stats[i].value) {
      return bgColor;
    }
    return undefined;
  };

  useLayoutEffect(() => {
    setHeight(size.height - 56);
  }, [size]);

  return (
    <div className="sticky top-0 z-50 flex flex-col justify-center h-full min-h-full overflow-auto bg-gray-200 font-jetbrains dark:bggradientlanding dark:darkscrollbar">
      <SecondaryNav
        className="text-black bg-gray-200 dark:text-white dark:bggradientlanding"
        menuItems={[
          loggedIn && { name: "Wallet", route: "/wallet" },
          { name: "Registry", route: "/registry" },
          { name: "FAQ", route: "/faq" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="flex flex-col h-auto min-h-screen m-auto overflow-y-auto mx-auto font-jetbrains w-full dark:darkscrollbar"
      >
        <div className="pb-6 pt-3 h-auto mx-auto w-11/12 4xl:w-8/12 3xl:w-9/12 2xl:w-10/12 xl:w-10/12 lg:w-11/12">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto lg:ml-1">
              <h1 className="font-semibold text-black text-lg sm:text-xl lg:text-2xl dark:text-white">
                BitMask Metrics
              </h1>
              <p className="mt-2 text-sm font-thin text-gray-900 sm:text-base lg:text-lg dark:text-gray-400">
                Data on all bitmask-core activity
              </p>
            </div>
          </div>
          {!loading && (
            <div className="bg-gray-300 dark:bggradient mt-2 rounded-lg">
              <div className="mx-auto max-w-7xl">
                <div className="grid gap-2 grid-cols-2 lg:grid-cols-4 p-2">
                  {metricData.current?.stats.map((stat, i) => (
                    <motion.div
                      className="bg-newdarkmode-700 rounded-md"
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                        backgroundColor: shouldUpdate(
                          stat.update,
                          stat.value,
                          i
                        ),
                      }}
                      transition={{
                        duration: stat.update ? 3.5 : 0.9,
                        delay: 0,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                      key={stat.name}
                    >
                      <div className="pr-5 pl-3 lg:pl-6 py-3 lg:px-4">
                        <p className="text-sm font-medium leading-6 text-gray-400">
                          {snakeToUpperCase(
                            String(stat.name).toLowerCase() === "total"
                              ? "Total Wallets"
                              : stat.name
                          )}
                        </p>
                        <p className="mt-2 flex items-baseline gap-x-2">
                          <span className="text-base md:text-lg font-semibold tracking-tight text-white">
                            {stat.value.toLocaleString()}
                          </span>
                          {stat.unit ? (
                            <span className="text-sm text-gray-400">
                              {stat.unit}
                            </span>
                          ) : null}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="py-3 mt-3 font-light bg-gray-300 rounded-lg flow-root dark:bggradient px-2 lg:py-4 dark:darkscrollbar">
            <div className="overflow-x-auto dark:darkscrollbar">
              <div className="inline-block w-full align-middle lg:px-4 px-1.5">
                {!loading ? (
                  <div className="rounded-md">
                    <LineChart
                      title="BitMask Wallets created"
                      data={[
                        {
                          title: "Bitcoin",
                          data: metricData.current.bitcoin[1],
                          bgColor: "#ff7f00",
                          plotColor: "#ff7f00",
                        },
                        {
                          title: "Testnet",
                          data: metricData.current.testnet[1],
                        },
                        {
                          title: "Regtest",
                          data: metricData.current.regtest[1],
                        },
                        {
                          title: "Signet",
                          data: metricData.current.signet[1],
                        },
                      ]}
                      labels={metricData.current.bitcoin[0]}
                      animation={animation}
                    />
                    <LineChart
                      title="Bytes uploaded to Carbonado"
                      data={[
                        {
                          title: "past 30 days",
                          data: Object.values(
                            metricData.current.bytesByMonth
                          ).slice(-30),
                          bgColor: "#000000",
                          plotColor: "#ffffff",
                          bgOpacity: 0.3,
                        },
                      ]}
                      labels={Object.keys(
                        metricData.current.bytesByMonth
                      ).slice(-30)}
                      animation={animation}
                    />
                    <LineChart
                      data={[
                        {
                          title: `Past ${metricData.current.weeksInDataSet} months`,
                          data: metricData.current.bytes[1],
                          bgColor: "#000000",
                          plotColor: "#ffffff",
                          bgOpacity: 0.3,
                        },
                      ]}
                      labels={metricData.current.bytes[0]}
                      animation={animation}
                    />
                  </div>
                ) : (
                  <div className="block w-full h-full m-auto mb-6 overflow-y-hidden text-center align-middle mt-9">
                    <Loader className="w-16 h-16 m-auto text-center align-middle" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </div>
  );
};

export default Metrics;
