import React from "react";

const Lightning = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} text-yellow-500`}
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 12 19"
  >
    <path
      d="M0 11.8689L9.45224 0L6.32767 7.63843L12 7.20539L2.49622 19L6.65167 10.6877L0 11.8689Z"
      fill="#FFCF02"
    />
  </svg>
);

export default Lightning;
