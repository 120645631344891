/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState } from "react";
import * as backend from "bitmask-segwit";
import { CARBONADO, init } from "bitmask-segwit/constants";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";
import TextField from "src/Components/Inputs/TextField";
import TermsOfService from "src/Components/Modals/Children/TermsOfService";
import Modal from "src/Components/Modals/Modal";
import useWindowSize from "src/Hooks/useWindowSize";
import { termsOfService } from "src/constants";
import { setHash } from "src/Hooks/util";

const CreatePassword = ({ handleChange, ...props }) => {
  const size = useWindowSize();
  const [agree, setAgree] = useState(false);
  const [_error, setError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [openTos, setOpenTos] = useState(false);

  const submitPassword = () => {
    props.setSteps([
      { name: "Set Password", href: "#", status: "complete" },
      { name: "Create Username", href: "#", status: "current" },
      { name: "Secure Wallet", href: "#", status: "upcoming" },
      { name: "Confirm Secret Backup Phrase", href: "#", status: "upcoming" },
    ]);
    handleChange("createUsername");
  };

  const handleSubmit = async () => {
    if (agree) {
      const { password, confirmPassword } = props;
      if (password === confirmPassword && password.length > 7) {
        setError(false);
        try {
          const hash = backend.bitcoin.hashPassword(password);
          const createWatcher = async (
            nostrKey: string,
            xpub: string
          ): Promise<void> => {
            if (CARBONADO) {
              await backend.rgb.createWatcher(nostrKey, {
                name: "default",
                xpub,
                force: true,
              });
            }
          };
          const createTestnetDescriptor = async () => {
            await backend.constants.switchNetwork(
              backend.constants.Network.testnet
            );
            window.localStorage.setItem(
              "network",
              backend.constants.Network.testnet
            );
            await init();
            const encryptedDescriptors = await backend.bitcoin.newWallet(
              hash,
              ""
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );
            /* await backend.rgb.watcher(vault.private.nostrPrv, {
              name: "default",
              xpub: vault.public.xpub,
            }); */
            await setHash(hash, vault.public.xpubkh);
            await backend.lightning.createWallet(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            window.localStorage.setItem(
              "testnetDescriptor",
              encryptedDescriptors
            );

            await createWatcher(
              vault.private.nostrPrv,
              vault.public.watcherXpub
            );
          };

          const createSignetDescriptor = async () => {
            await backend.constants.switchNetwork(
              backend.constants.Network.signet
            );
            window.localStorage.setItem(
              "network",
              backend.constants.Network.signet
            );
            const encryptedDescriptors = await backend.bitcoin.newWallet(
              hash,
              ""
            );
            window.localStorage.setItem(
              "signetDescriptor",
              encryptedDescriptors
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );
            await createWatcher(
              vault.private.nostrPrv,
              vault.public.watcherXpub
            );
          };
          const createRegtestDescriptor = async () => {
            await backend.constants.switchNetwork(
              backend.constants.Network.regtest
            );
            window.localStorage.setItem(
              "network",
              backend.constants.Network.regtest
            );
            const encryptedDescriptors = await backend.bitcoin.newWallet(
              hash,
              ""
            );
            window.localStorage.setItem(
              "regtestDescriptor",
              encryptedDescriptors
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );
            await createWatcher(
              vault.private.nostrPrv,
              vault.public.watcherXpub
            );
          };
          const createBitcoinDescriptor = async () => {
            await backend.constants.switchNetwork(
              backend.constants.Network.bitcoin
            );
            window.localStorage.setItem(
              "network",
              backend.constants.Network.bitcoin
            );
            await init();
            const encryptedDescriptors = await backend.bitcoin.newWallet(
              hash,
              ""
            );
            window.localStorage.setItem(
              "bitcoinDescriptor",
              encryptedDescriptors
            );
            const vault = await backend.bitcoin.decryptWallet(
              hash,
              encryptedDescriptors
            );
            await createWatcher(
              vault.private.nostrPrv,
              vault.public.watcherXpub
            );
            await backend.lightning.createWallet(
              vault.public.xpubkh,
              vault.private.xprvkh
            );
            props.setVault(vault);
            props.setMnemonic(vault.mnemonic.split(" ").slice(0, 24));
            console.debug("got mnemonic");
            submitPassword();
          };
          await createTestnetDescriptor();
          await createSignetDescriptor();
          await createRegtestDescriptor();
          await createBitcoinDescriptor();
        } catch (error) {
          console.error("error in CreatePassword", error);
          setError(true);
        }
      } else {
        setError(true);
      }
    } else {
      setTermsError(true);
    }
  };

  return (
    <div className="w-full h-auto py-10 m-auto mx-3 text-center xs:mx-6 sm:m-auto sm:shadow-xl sm:w-136 rounded-xl xs:bggradientlanding">
      <Circles steps={props.steps} />
      <h1 className="mt-6 text-2xl font-bold text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
        Create Password
      </h1>
      <p className="w-11/12 m-auto mt-2 text-lg font-light text-left text-gray-900 sm:text-xl xs:w-9/12 dark:text-gray-400 flex-grow-default leading-7 sm:leading-8">
        Your password allows you to log in to your BitMask account. This is
        different from your seed phrase.
      </p>
      <TextField
        type="password"
        className="w-11/12 p-3 mt-1 xs:w-9/12"
        placeholder="Create Password"
        autoComplete="new-password"
        onChange={(e) => props.setPassword(e.target.value)}
        darkest={size.width >= 500}
      />
      {props.password.length < 8 && props.password !== "" && (
        <div className="w-11/12 m-auto xs:w-9/12">
          <p className="-mb-2 text-sm text-left text-red-600 flex-grow-default">
            Must be 8 characters
          </p>
        </div>
      )}
      <TextField
        type="password"
        className="w-11/12 p-3 mt-2 xs:w-9/12"
        placeholder="Confirm Password"
        autoComplete="new-password"
        onChange={(e) => props.setConfirmPassword(e.target.value)}
        onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
        darkest={size.width >= 500}
      />
      {props.password !== props.confirmPassword &&
        props.confirmPassword !== "" && (
          <div className="w-11/12 m-auto xs:w-9/12">
            <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
              passwords must match
            </p>
          </div>
        )}
      {termsError && (
        <div className="w-11/12 m-auto xs:w-9/12">
          <p className="text-sm font-semibold text-left text-red-600 flex-grow-default">
            You must agree to the Terms of Service to create an account.
          </p>
        </div>
      )}
      <div className="flex w-11/12 m-auto mt-5 xs:w-9/12 flex-nowrap">
        <input
          type="checkbox"
          className="w-6 h-6 my-auto mr-3"
          onChange={() => {
            setAgree(!agree);
            setTermsError(false);
          }}
          onKeyDown={(e) =>
            e.key === "Enter"
              ? () => {
                  setAgree(!agree);
                  setTermsError(false);
                }
              : null
          }
        />
        <p className="my-auto text-sm font-normal text-left text-gray-900 dark:text-gray-400">
          Click to agree to our terms of service,{" "}
          <button
            type="button"
            className="text-gray-700 underline dark:text-green-500"
            onClick={() => setOpenTos(true)}
          >
            Terms of Service.
          </button>
        </p>
      </div>
      <RoundedButton
        className="w-9/12 py-3 mx-auto mt-8 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
        onClick={() => handleSubmit()}
      >
        <span className="m-auto">Create a new wallet</span>
      </RoundedButton>
      <Modal open={openTos} setOpen={setOpenTos}>
        <TermsOfService tos={termsOfService} />
      </Modal>
    </div>
  );
};

export default CreatePassword;
