/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as backend from "bitmask-segwit";
import { Activity, Transaction } from "bitmask-segwit/bitcoin";

import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ArrowRight from "src/Components/Icons/ArrowRight";
import ErrorModal from "src/Components/Modals/Error";
import lib from "src/lib";
import { getConversion, getFeeRate } from "src/Hooks/util";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import {
  ifaceTokens,
  ifaceUdas,
  terminalTokens,
  terminalUdas,
} from "src/constants";
import { transferAssetsWithFeeRate } from "src/aux/transferAssets";
import { contractAmountParseStr } from "bitmask-segwit/rgb";

// TODO: clarify Asset, Assets and token naming

const updateActivities = async (transaction: Transaction, wallet: string) => {
  if (
    transaction.assetType === "Asset" ||
    transaction.assetType === "UDA" ||
    transaction.assetType === "Assets" ||
    transaction.assetType === "token"
  ) {
    const activity: Activity = {
      date: Date.now(),
      id: uuidv4(),
      action: "sent",
      status: "pending",
      ...transaction,
    };
    const key = `${wallet}.asset_activities`;
    const result = await lib.storageGet<Activity[]>([key]);
    let activities: Activity[] = [];
    if (result[key]) {
      activities = result[key];
    }
    activities.push(activity);
    await lib.storageSet({ [key]: activities });
    console.debug(`activities: ${activities}`);
  }
};

const Confirm = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    transaction: transactionBase,
    walletData,
    vault,
    asset,
    locationHistory,
    hash,
    lnCredentials,
    feeRate,
  } = location.state;

  const network = window.localStorage.getItem("network");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState(0);

  const transaction: Activity = {
    ...transactionBase,
    amount: transactionBase.assetType === "UDA" ? 1 : transactionBase.amount,
  };

  useEffect(() => {
    if (transactionBase.assetType === "token")
      Mixpanel.track("Viewed Send Asset Confirmation", {
        Transaction: transactionBase,
        Asset: asset,
      });
    Mixpanel.track("Viewed Transaction Confirmation Page", {
      Transaction: transactionBase,
    });
  }, []);

  const handleSubmit = async () => {
    try {
      if (
        transaction.assetType === "Asset" ||
        transaction.assetType === "token" ||
        transaction.assetType === "UDA" ||
        transaction.assetType === "Assets"
      ) {
        const optimalFee = await getFeeRate();
        if (feeRate > optimalFee * 2 || feeRate < 1) {
          setError({
            title: `Error sending payment`,
            message: `Fee rate is set out of range, allowed range is between 1 and ${
              optimalFee * 2
            } sats/vByte.`,
          });
          setOpen(true);
        } else {
          setLoading(true);
          if (!transaction.recipient?.invoice) {
            throw new Error("No invoice provided");
          }
          if (!transaction.asset) {
            throw new Error("No transaction asset provided");
          }
          const assetsVaultDetails = await backend.bitcoin.getAssetsVault(
            vault.public.rgbAssetsDescriptorXpub,
            vault.public.rgbUdasDescriptorXpub
          );
          const descriptorXpub =
            transaction.assetType === "UDA"
              ? vault.public.rgbUdasDescriptorXpub
              : vault.public.rgbAssetsDescriptorXpub;
          const descriptorXprv =
            transaction.assetType === "UDA"
              ? vault.private.rgbUdasDescriptorXprv
              : vault.private.rgbAssetsDescriptorXprv;
          const iface =
            transaction.assetType === "UDA" ? ifaceUdas : ifaceTokens;
          const terminal =
            transaction.assetType === "UDA" ? terminalUdas : terminalTokens;
          const { sign, consig } = await transferAssetsWithFeeRate(
            vault,
            asset,
            transaction.recipient?.invoice,
            descriptorXpub,
            descriptorXprv,
            iface,
            terminal,
            feeRate !== 0 ? feeRate : optimalFee
          );
          if (sign) {
            // console.info("Asset transfer signed tx id:", txid);
            console.info("Asset transfer consignment:", consig);
            // transaction.id = txid;

            updateActivities(transaction, walletData.name);
            navigate("/success", {
              state: {
                wallet: walletData.name,
                vault,
                hash,
                transaction,
                lnCredentials,
                asset,
                consignment: consig,
              },
            });
          } else {
            setError({
              title: `Error sending payment`,
              message: "Error validating transaction",
            });
            setOpen(true);
          }
        }
      } else {
        const optimalFee = await getFeeRate();
        if (feeRate > optimalFee * 2 || feeRate < 1) {
          setError({
            title: `Error sending payment`,
            message: `Fee rate is set out of range, allowed range is between 1 and ${
              optimalFee * 2
            } sats/vByte.`,
          });
          setOpen(true);
        } else {
          setLoading(true);

          if (!transaction.recipient?.address) {
            throw new Error("No address for transaction recipient provided");
          }

          // If transaction amount is for the same number of sats in the wallet, drain the wallet
          if (transaction.amount === walletData.balance.confirmed) {
            const txResponse = await backend.bitcoin.drainWallet(
              transaction.recipient.address,
              vault.private.btcDescriptorXprv,
              vault.private.btcChangeDescriptorXprv,
              feeRate !== 0 ? feeRate : optimalFee
            );

            transaction.id = txResponse.details.txid;
          } else {
            const txResponse = await backend.bitcoin.sendSats(
              vault.private.btcDescriptorXprv,
              vault.private.btcChangeDescriptorXprv,
              transaction.recipient.address,
              BigInt(transaction.amount),
              feeRate !== 0 ? feeRate : optimalFee
            );

            transaction.id = txResponse.details.txid;
          }

          updateActivities(transaction, walletData.name);

          navigate("/success", {
            state: {
              wallet: walletData.name,
              vault,
              transaction,
              hash,
              lnCredentials,
            },
          });
        }
      }
    } catch (err: any) {
      setLoading(false);
      if (err.toString().includes("InsufficientFunds")) {
        setError({
          title: `Error sending payment`,
          message: err,
        });
      } else if (err.toString().includes("Error on address")) {
        setError({
          title: `Error sending payment`,
          message: "Invalid address.",
        });
      } else {
        setError({
          title: `Error sending payment`,
          message: JSON.stringify(err),
        });
      }
      setOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  return (
    <PageWrapper
      title="Confirm Transaction"
      handlePageBack={() =>
        navigate(locationHistory.pop()?.replace("#", "") || "/", {
          state: {
            ...location.state,
          },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={() => handleSubmit()}
      loading={loading}
      confirmation
    >
      <div className="sm:bg-gray-300 sm:dark:bg-newdarkmode-800 sm:rounded-lg sm:shadow-lg sm:p-12">
        <div className="w-8/12 p-6 m-auto mb-6 text-center bg-gray-300 rounded-lg shadow-lg dark:bg-newdarkmode-800 sm:shadow-md sm:mb-12">
          <h2 className="text-2xl font-semibold text-black dark:text-gray-300">
            {transaction.assetType !== "UDA" &&
              Number(
                contractAmountParseStr(
                  transaction.amount.toString(),
                  asset.precision
                ).toString()
              ).toLocaleString()}{" "}
            {asset.ticker}
          </h2>
          {["sats", "Lightning"].includes(transaction.assetType) && (
            <h2 className="text-base font-normal text-gray-800 dark:text-gray-500">
              {Number(
                (Number(transaction.amount) / 100_000_000) * conversion
              ).toLocaleString(undefined, { minimumFractionDigits: 3 })}{" "}
              USD
            </h2>
          )}
        </div>
        <div className="mt-6 grid grid-cols-7">
          <div className="w-full col-span-3">
            <h3 className="mb-1 text-sm font-semibold text-gray-700 sm:text-base dark:text-gray-400">
              From
            </h3>
            <div className="flex w-auto h-12 px-2 my-auto mt-1 text-left rounded-lg flex-nowrap">
              <p className="my-auto mr-2 text-base font-medium text-black sm:text-lg dark:text-gray-300">
                {transaction.sender?.name}:{" "}
              </p>
              <p className="my-auto text-base font-normal text-gray-800 truncate sm:text-lg dark:text-gray-400">
                {transaction.sender?.address}
              </p>
            </div>
          </div>
          <div className="m-auto col-span-1">
            <ArrowRight className="mt-6 text-black w-7 h-7 dark:text-gray-500" />
          </div>
          <div className="w-full col-span-3">
            <h3 className="mb-1 text-sm font-semibold text-gray-700 sm:text-base dark:text-gray-400">
              To
            </h3>
            <div className="flex w-auto h-12 px-2 my-auto mt-1 text-left rounded-lg flex-nowrap">
              <p className="my-auto mr-2 text-base font-medium text-black sm:text-lg dark:text-gray-300">
                {transaction.recipient?.name}:{" "}
              </p>
              <p className="my-auto text-base font-normal text-gray-800 truncate sm:text-lg dark:text-gray-400">
                {transaction.recipient?.address
                  ? transaction.recipient?.address
                  : transaction.recipient?.invoice}
              </p>
            </div>
          </div>
        </div>
        <div className="hidden">
          {network === "bitcoin" && (
            <>
              <h3 className="mt-4 mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
                Transaction Fee
              </h3>
              <div className="grid grid-cols-2">
                <div className="mr-5">
                  <div className="flex flex-col w-auto px-2 py-1 m-auto mt-1 text-center rounded-lg">
                    <p className="m-auto text-base text-black truncate ont-light dark:text-gray-300">
                      {Number(transaction.fee).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {transaction.assetType !== "UDA" && (
          <div className="mt-4 flex-grow-default">
            <h3 className="mb-1 text-sm font-semibold text-gray-700 sm:text-base dark:text-gray-400">
              Total
            </h3>
            <div className="flex w-auto px-1 py-3 m-auto mt-1 text-base text-left rounded-lg flex-nowrap sm:text-lg">
              <p className="my-auto font-semibold text-black truncate dark:text-white">
                {(
                  Number(
                    contractAmountParseStr(
                      transaction.amount.toString(),
                      asset.precision
                    ).toString()
                  ) + transaction.fee
                ).toLocaleString()}{" "}
                {asset.ticker}
              </p>
              {["sats", "Lightning"].includes(transaction.assetType) && (
                <p className="my-auto ml-auto font-semibold text-right text-gray-800 truncate dark:text-gray-300">
                  {(
                    (Number(transaction.amount) / 100_000_000) * conversion +
                    (transaction.fee / 100_000_000) * conversion
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 3,
                  })}{" "}
                  USD
                </p>
              )}
            </div>
            {["sats", "Lightning"].includes(transaction.assetType) && (
              <h3 className="mt-1 ml-1 text-sm font-medium text-gray-800 dark:text-gray-500">
                Amount + Fee
              </h3>
            )}
          </div>
        )}
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default Confirm;
