import React, { useEffect } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import Layout from "src/Components/Layout/Layout";
import Layout2 from "src/Components/Layout/Layout2";
import NewUser from "src/Pages/NewUser";
import Faq from "src/Pages/Faq";
import Blog from "src/Pages/Blog";
import Welcome from "src/Pages/Welcome";
import WalletImport from "src/Pages/WalletImport";
import PaperWallet from "src/Pages/PaperWallet";
import Wallet from "src/Pages/Wallet";
import ImportAsset from "src/Pages/ImportAsset";
import ImportDescription from "src/Pages/ImportDescription";
import Send from "src/Pages/Send";
import Confirm from "src/Pages/Confirm";
import Success from "src/Pages/Success";
import ImportAssetConfirm from "src/Pages/ImportAssetConfirm";
import ViewTransaction from "src/Pages/ViewTransaction";
import Receive from "src/Pages/Receive";
import AcceptTransaction from "src/Pages/AcceptTransaction";
import AssetDetails from "src/Pages/AssetDetails";
import WalletSetup from "src/Pages/WalletSetup";
import SignIn from "src/Pages/SignIn";
import AuthWrapper from "src/Hooks/Auth";
import MainNavContextProvider from "src/Hooks/MainNavContext";
import ThemeContextProvider from "src/Hooks/ThemeContext";
import UniqueDigitalAsset from "src/Pages/UniqueDigitalAsset";
import CreateAsset from "src/Pages/CreateAsset";
import LightningConfirm from "./Pages/LightningConfirm";
import MarketplaceActions from "./Pages/MarketplaceActions";
import Settings from "./Pages/Settings";
import AccountDetails from "./Pages/AccountDetails";
import Registry from "./Pages/Registry";
import HomeAuthWrapper from "./Hooks/HomeAuth";
import Metrics from "./Pages/Metrics";

const App = () => {
  useEffect(() => {
    function resize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    window.addEventListener("resize", resize);
    window.addEventListener("load", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("load", resize);
    };
  });

  return (
    <ThemeContextProvider>
      <MainNavContextProvider>
        <HashRouter>
          <Routes>
            <Route path="/registry" element={<Registry />} />
            <Route path="/home" element={<Welcome />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/metrics" element={<Metrics />} />
            <Route element={<Layout />}>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/settings" element={<Settings />} />
            </Route>
            <Route element={<AuthWrapper />}>
              <Route path="/" element={<Welcome />} />
              <Route element={<Layout />}>
                <Route path="/registry" element={<Registry />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/importassets" element={<ImportAsset />} />
                <Route
                  path="/importdescription"
                  element={<ImportDescription />}
                />
                <Route
                  path="/importassetconfirm"
                  element={<ImportAssetConfirm />}
                />
                <Route path="/send" element={<Send />} />
                <Route
                  path="/lightningconfirm"
                  element={<LightningConfirm />}
                />
                <Route path="/confirm" element={<Confirm />} />
                <Route path="/success" element={<Success />} />
                <Route path="/viewtransaction" element={<ViewTransaction />} />
                <Route path="/receive" element={<Receive />} />
                <Route path="/assetdetails" element={<AssetDetails />} />
                <Route
                  path="/accepttransaction"
                  element={<AcceptTransaction />}
                />
                <Route path="/uda" element={<UniqueDigitalAsset />} />
                <Route path="/createasset" element={<CreateAsset />} />
                <Route
                  path="/marketplaceaction"
                  element={<MarketplaceActions />}
                />
                <Route path="/account" element={<AccountDetails />} />
              </Route>
              <Route element={<Layout2 />}>
                <Route element={<HomeAuthWrapper />}>
                  <Route path="/walletimport" element={<WalletImport />} />
                  <Route
                    path="/walletimport/:preload"
                    element={<WalletImport />}
                  />
                  <Route path="/paperwallet" element={<PaperWallet />} />
                  <Route path="/paperwallet/:pin" element={<PaperWallet />} />
                  <Route path="/walletsetup" element={<WalletSetup />} />
                  <Route path="/newuser" element={<NewUser />} />
                </Route>
                <Route path="/blog/:title" element={<Blog />} />
              </Route>
            </Route>
          </Routes>
        </HashRouter>
      </MainNavContextProvider>
    </ThemeContextProvider>
  );
};

export default App;
