/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, useLayoutEffect } from "react";

import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import Copy from "src/Components/Icons/Copy";
import Loader from "src/Components/Loaders/Loader";
import Pagination from "src/Components/Navigation/Pagination";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import { isExtension } from "src/lib";
import { getLargeNumber } from "src/Hooks/util";
import useWindowSize from "src/Hooks/useWindowSize";

const Registry = () => {
  // eslint-disable-next-line no-unused-vars
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const [page, setPage] = useState(1);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [assets, setAssets] = useState<any[]>([]);
  const [loggedIn, setLoggedIn] = useState(true);
  const network = window.localStorage.getItem("network");
  const [openCopyModal, setOpenCopyModal] = useState(false);

  const isPopup = window.location.href.split("?").pop()?.includes("popup");

  const handleRedirectToFullscreenTab = () => {
    window.close();
    const win = window.open(
      `${window.location.href.split("?")[0]}/#/registry`,
      "_blank"
    );
    win?.focus();
  };

  useEffect(() => {
    if (
      isPopup &&
      (window.location.href.split("/").pop() === "index.html" ||
        window.location.href.split("/").pop() === "registry")
    ) {
      handleRedirectToFullscreenTab();
    }
  }, []);

  useEffect(() => {
    const getAssets = async () => {
      setLoading(true);
      const assetSet = await fetch(
        `${isExtension ? "https://beta.bitmask.app/" : "/"}paginate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            page,
            size: 10,
          }),
        }
      );
      const resp = await assetSet.json();
      if (resp.data) setLoading(false);
      setAssets(resp.data);
      setAmount(resp.amount);
    };
    const loadAssets = async () => {
      setLoggedIn(false);
      if (localStorage.getItem(`${network}Descriptor`)) {
        setLoggedIn(true);
        getAssets();
      } else {
        getAssets();
      }
    };
    loadAssets();
  }, [page]);

  useLayoutEffect(() => {
    setHeight(size.height - 56);
  }, [size]);

  return (
    <div className="sticky top-0 z-50 flex flex-col justify-center h-full min-h-full overflow-auto bg-gray-200 font-jetbrains dark:bggradientlanding dark:darkscrollbar">
      <SecondaryNav
        className="text-black bg-gray-200 dark:text-white dark:bggradientlanding"
        menuItems={[
          loggedIn && { name: "Wallet", route: "/wallet" },
          { name: "Metrics", route: "/metrics" },
          { name: "FAQ", route: "/faq" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="flex flex-col h-auto min-h-screen m-auto overflow-y-auto mx-auto font-jetbrains w-full dark:darkscrollbar"
      >
        <div className="pb-6 pt-3 h-auto mx-auto w-11/12 4xl:w-8/12 3xl:w-9/12 2xl:w-10/12 xl:w-10/12 lg:w-11/12">
          <div className="sm:flex sm:items-center lg:mt-6">
            <div className="sm:flex-auto lg:ml-1">
              <h1 className="font-semibold text-black text-lg sm:text-xl lg:text-2xl dark:text-white">
                BitMask Asset Registry
              </h1>
              <p className="mt-2 text-sm font-thin text-gray-900 lg:mt-4 sm:text-base lg:text-lg dark:text-gray-400">
                Data on all publicly listed RGB assets created on BitMask
              </p>
            </div>
          </div>
          <div className="py-3 mt-3 font-light bg-gray-300 rounded-t-lg flow-root dark:bggradient px-2 lg:py-4 dark:darkscrollbar">
            <div className="overflow-x-auto dark:darkscrollbar">
              <div className="inline-block w-full align-middle lg:px-4 px-1.5">
                {!loading ? (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr className="text-left text-gray-900 dark:text-gray-400">
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Number
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Ticker
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Supply
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Precision
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Username
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Creation date
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Block height
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Contract ID
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap pr-3 lg:pr-6 py-3.5 text-left text-sm lg:text-base font-normal"
                        >
                          Genesis
                        </th>
                        <th
                          scope="col"
                          className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-full my-auto divide-y divide-gray-200">
                      {assets.map((asset) => (
                        <tr key={asset.id}>
                          <td className="py-2 pl-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.number}
                          </td>
                          <td className="py-3 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.ticker}
                          </td>
                          <td className="py-2 pr-3 text-sm text-gray-900 lg:pr-6 whitespace-nowrap dark:text-white">
                            {Number(asset.supply) > 100000
                              ? getLargeNumber(Number(asset.supply))
                              : Number(asset.supply).toLocaleString()}
                          </td>
                          <td className="py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.precision}
                          </td>
                          <td className="py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.username}
                          </td>
                          <td className="py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {new Date(asset.timestamp).toLocaleDateString()}
                          </td>
                          <td className="py-2 pr-3 text-sm text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            {asset.blockHeight}
                          </td>
                          <td className="py-2 pr-3 text-xs text-black lg:pr-6 whitespace-nowrap dark:text-white">
                            <button
                              type="button"
                              className="flex flex-row truncate flex-nowrap bg-gray-350 dark:bg-newdarkmode-600 hover:bg-opacity-25 dark:hover:text-gray-500 py-1 px-1.5 rounded-md"
                              onClick={() => {
                                navigator.clipboard.writeText(asset.contractId);
                                setOpenCopyModal(true);
                              }}
                            >
                              <p className="truncate max-w-28">
                                {asset.contractId}
                              </p>
                              <Copy className="w-5 h-5 my-auto ml-1" />
                            </button>
                          </td>
                          <td className="py-2 pr-3 text-xs text-black lg:pr-6 whitespace-nowrap dark:text-white max-w-36">
                            <button
                              type="button"
                              className="flex flex-row truncate flex-nowrap bg-gray-350 dark:bg-newdarkmode-600 hover:bg-opacity-25 dark:hover:text-gray-500 py-1 px-1.5 rounded-md"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  asset.contractGenesis
                                );
                                setOpenCopyModal(true);
                              }}
                            >
                              {" "}
                              <p className="truncate max-w-28">
                                {asset.contractGenesis}
                              </p>
                              <Copy className="w-5 h-5 my-auto ml-1" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="block w-full h-full m-auto mb-6 overflow-y-hidden text-center align-middle mt-9">
                    <Loader className="w-16 h-16 m-auto text-center align-middle" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Pagination page={page} setPage={setPage} amount={amount} />
        </div>
      </div>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </div>
  );
};

export default Registry;
