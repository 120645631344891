/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

const TermsOfService = ({ ...props }) => (
  <div className="px-3 py-6 font-light dark:bggradientlanding sm:px-6 sm:py-12">
    <h1 className="pb-3 text-xl font-medium xs:text-3xl dark:text-white">
      BitMask Testnet Wallet Terms of Service
    </h1>
    <p className="pt-6 pb-6 text-base text-left text-black whitespace-pre-line leading-9 sm:text-lg sm:pb-12 dark:text-white">
      These Testnet Terms of Service (“Service Terms”) apply to and govern your
      and your permitted users’ access to and use of the Bitcoin Testnet Wallet
      extension and tools by BitMask as described at{" "}
      <a href="https://bitmask.app" className="underline">
        https://bitmask.app
      </a>
      . These Service Terms are additional terms that, when accepted by you,
      form part of the Agreement as defined in the Terms of Use published on the
      BitMask Website (and to which you have agreed to be bound).
    </p>
    <ul className="flex flex-col px-3 list-decimal list-inside md:list-outside sm:space-y-9 xl:space-y-12">
      {props.tos.map((note) => (
        <li className="w-full text-base text-left leading-9 dark:text-gray-300 xs:text-xl">
          <p className="pb-3 text-lg sm:text-xl sm:pb-6">{note.title}</p>
          {note.body}
        </li>
      ))}
    </ul>
  </div>
);

export default TermsOfService;
