/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";

import { classNames, getFeeRate } from "src/Hooks/util";
import Refresh from "../Icons/Refresh";

interface Props {
  className?: string;
  darkest?: boolean;
  label: string;
  optimalFee: number;
  fee: number;
  onFeeChange: (v: number) => void;
  onFeeRefresh: (v: number) => void;
}

const SetFeeRate: React.FC<Props> = ({ className = "", ...props }) => {
  const handleRefreshFeeRate = async () => {
    const feeRate = await getFeeRate();
    props.onFeeChange(feeRate);
    props.onFeeRefresh(feeRate);
  };

  return (
    <div className="flex flex-col justify-center w-full my-4 text-black dark:text-white">
      <h3 className="mb-2 text-xs font-semibold text-left text-black xs:text-sm dark:text-gray-400">
        {props.label} (optimal fee: {props.optimalFee} sats/vByte)
      </h3>
      <div className="flex flex-row">
        <input
          type="number"
          min="1"
          max={props.optimalFee * 2}
          value={props.fee}
          className={classNames(
            className,
            "bg-gray-200 focus:bg-gray-300 rounded-md text-md focus:outline disabled:opacity-50 disabled:cursor-not-allowed mx-auto focus:text-gray-900 placeholder-gray-600 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600 focus:ring-1 focus:ring-gray-500 dark:focus:ring-newdarkmode-600 text-base p-3 w-full",
            props.darkest
              ? "dark:bg-newdarkmode-800"
              : "dark:bg-newdarkmode-700"
          )}
          onChange={(e) => props.onFeeChange(parseFloat(e.target.value))}
        />
        <button
          type="button"
          title="refresh optimal fee rate"
          onClick={() => handleRefreshFeeRate()}
          className="ml-2 hover:opacity-75 opacity-[90%] dark:opacity-100 dark:hover:opacity-75"
        >
          <Refresh className="text-gray-800 w-7 h-7 dark:text-gray-400" />
        </button>
      </div>
      <input
        id="feerate"
        type="range"
        className="mt-3"
        value={props.fee}
        min={1}
        max={props.optimalFee * 2}
        step={1}
        onChange={(e) => props.onFeeChange(parseFloat(e.target.value))}
        list="markers"
      />
      <datalist id="markers">
        <option
          value="1"
          label="1 sat/vByte"
          className="text-xs font-thin text-black dark:text-white"
        />
        <option
          value={props.optimalFee}
          label="optimal fee"
          className="text-xs font-thin text-black dark:text-white"
        />
        <option
          value={props.optimalFee * 2}
          label={`${props.optimalFee * 2} sats/vByte`}
          className="text-xs font-thin text-black dark:text-white"
        />
      </datalist>
    </div>
  );
};

export default SetFeeRate;
