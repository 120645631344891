/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as backend from "bitmask-segwit";

import { Location } from "src/types";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2"; // @ts-ignore: no-any
import { ImportResponse } from "src/types/core";
import ViewMediaFullscreen from "src/Components/Modals/ViewMediaFullscreen";
import { Menu, Transition } from "@headlessui/react";
import Dots from "src/Components/Icons/Dots";
import FullScreen from "src/Components/Icons/FullScreen";
import DefaultMenu from "src/Components/Menus/Menu";
import { SpeakerXMarkIcon, SpeakerWaveIcon } from "@heroicons/react/24/outline";
import AudioPlayback from "src/Components/AudioPlayer/AudioPlayback";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";

const UniqueDigitalAsset = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    assetUtxos,
    vault,
    uda,
    pendingUdaTransfers,
    lnCredentials,
    locationHistory,
    hash,
  } = location.state;
  // TODO: review this assignation and if we can use collectible again
  // const collectible: backend.rgb.UDADetail[] = uda.meta?.collectible || [];
  const [media, ...rest]: backend.rgb.MediaInfo[] = uda.meta?.attachments /* ||
    (collectible.length && collectible[0]?.media) */ || [
    { type: "", source: "" },
  ];
  let cover = { type: "", source: "" };
  // eslint-disable-next-line prefer-destructuring
  if (rest.length > 0) cover = rest[0];
  const [openFullScreenModal, setFullScreenModal] = useState(false);
  const [muted, setMuted] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const audioRef = useRef<HTMLAudioElement>();

  return (
    <PageWrapper2
      handlePageBack={() => {
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        });
      }}
      className=""
      title="View UDA"
      button={null}
    >
      {(media.type.includes("image") || media.type === "a/a") && (
        <div className="relative z-0 flex justify-center w-full mx-auto h-[75vw] md:h-[60vh] bg-gray-300 dark:bg-newdarkmode-800 mt-1.5">
          <button
            className="w-full h-full p-3 xs:p-6 md:p-12"
            type="button"
            onClick={() => {
              setFullScreenModal(true);
            }}
          >
            <img
              key={uda.contractId}
              src={media.source}
              className="object-contain w-full h-auto max-h-full m-auto"
              alt="unique digital asset"
            />
          </button>
        </div>
      )}
      {media.type === "video/mp4" && (
        <div className="relative z-0 flex justify-center w-full mx-auto h-[75vw] md:h-[60vh] bg-gray-300 dark:bg-newdarkmode-800 mt-1.5">
          <video
            autoPlay
            loop
            muted
            controls
            playsInline
            crossOrigin="anonymous"
            controlsList="nodownload"
            key={uda.contractId}
            src={media.source}
            className="object-contain w-full h-auto max-h-full m-auto"
          />
        </div>
      )}
      {media.type === "audio/mpeg" && (
        <div className="flex flex-col">
          <div className="relative z-0 w-full mx-auto h-[75vw] md:h-[60vh]">
            <div className="h-full">
              <div className="absolute top-0 pt-6 pl-5 ease-in-out lg:pl-12">
                <div className="flex justify-center m-auto flex-nowrap">
                  <button
                    className="w-10 h-10 text-black bg-gray-500 rounded-full xs:h-12 xs:w-12 dark:bg-black bg-opacity-50 dark:bg-opacity-50 dark:text-white"
                    type="button"
                    onClick={() => {
                      if (audioRef.current) {
                        audioRef.current.muted = !audioRef.current.muted;
                        setMuted(!muted);
                      }
                    }}
                  >
                    {muted ? (
                      <SpeakerXMarkIcon className="m-auto h-7 w-7 xs:h-8 xs:w-8" />
                    ) : (
                      <SpeakerWaveIcon className="m-auto h-7 w-7 xs:h-8 xs:w-8" />
                    )}
                  </button>
                </div>
              </div>
              <img
                key={uda.contractId}
                src={
                  // uda.audioCoverArt
                  // ? uda.audioCoverArt
                  "/images/defaultAudioCoverArt.webp"
                }
                className="object-cover w-full h-auto max-h-full m-auto"
                alt="unique digital asset"
              />
              <AudioPlayback track={media.source} audioRef={audioRef} />
            </div>
          </div>
        </div>
      )}
      <div className="w-full h-auto mt-1.5 mb-9">
        <div className="flex justify-center w-full">
          <div className="flex flex-row w-full my-auto gap-3">
            <div className="flex my-auto mr-auto flex-nowrap">
              <h1 className="my-3 text-xl font-normal text-black lg:text-2xl dark:text-white ml-1.5">
                {uda.name}
              </h1>
            </div>
            <div className="my-auto ml-auto">
              <DefaultMenu
                title="UDA Options"
                items={[
                  {
                    name: "Copy ID",
                    onClick: () => {
                      navigator.clipboard.writeText(uda.contractId || "");
                      setOpenCopyModal(true);
                    },
                  },
                  {
                    name: "Copy Contract",
                    onClick: () => {
                      navigator.clipboard.writeText(uda.genesis.strict || "");
                      setOpenCopyModal(true);
                    },
                  },
                  {
                    name: "Transfer",
                    onClick: () => {
                      navigate("/send", {
                        state: {
                          ...location.state,
                          locationHistory: [
                            ...locationHistory,
                            window.location.hash,
                          ],
                          walletData,
                          vault,
                          asset: uda,
                          type: "UDA",
                        },
                      });
                    },
                    disabled: pendingUdaTransfers,
                  },
                  {
                    name: "Burn",
                    onClick: () => {},
                    disabled: true,
                  },
                ]}
                className="m-auto"
              >
                <Dots className="my-auto text-gray-800 h-7 w-7 md:h-8 md:w-8 rotate-90 dark:text-gray-400" />
              </DefaultMenu>
            </div>
          </div>
        </div>
        <p className="h-auto overflow-hidden text-base font-light text-left text-black md:text-lg dark:text-white line-clamp-3">
          {uda.description}
        </p>
      </div>
      {/* <div className="m-auto">
        <RoundedButton
          className="px-6 m-auto mt-6 mb-12 text-base text-black bg-yellow-500"
          onClick={() => {
            if (uda.asset) {
              // TODO: must be to wait until uda.asset is there
              navigate("/send", {
                state: {
                  ...location.state,
                  locationHistory: [...locationHistory, window.location.hash],
                  walletData,
                  vault,
                  asset: uda.asset,
                  type: "UDA",
                },
              });
            }
          }}
        >
          Send UDA
        </RoundedButton>
      </div> */}
      {(media.type.includes("image") || media.type === "a/a") && (
        <ViewMediaFullscreen
          open={openFullScreenModal}
          setOpen={setFullScreenModal}
          uda={media.source}
        />
      )}
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
    </PageWrapper2>
  );
};

export default UniqueDigitalAsset;
