import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import * as backend from "bitmask-segwit";
import { CARBONADO, DISABLE_LN, LNDHUBX } from "bitmask-segwit/constants";
import { createInvoice } from "bitmask-segwit/rgb";

import { Location } from "src/types";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import PageWrapper2 from "src/Components/Layout/Wrappers/PageWrapper2";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import ErrorModal from "src/Components/Modals/Error";
import Modal from "src/Components/Modals/Modal";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import TextField from "src/Components/Inputs/TextField";
import { addBalance, lndhubError } from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import CopyButton from "src/Components/Buttons/CopyButton";
import { ifaceTokens, ifaceUdas, sealPrefix } from "src/constants";

const setInvoice = async (
  vault,
  contractId,
  amount: number,
  type,
  precision: number,
  assetUtxos
) => {
  const currentUtxo =
    type === "UDA" ? assetUtxos.currentUtxoUdas : assetUtxos.currentUtxoTokens;
  const seal = `${sealPrefix}:${currentUtxo}`;
  const amountText = String(amount);
  const finalAmount = backend.rgb
    .contractAmountParseStr(amountText, precision)
    .toString();
  const invoiceResponse = await createInvoice(vault.private.nostrPrv, {
    contractId,
    iface: type === "UDA" ? ifaceUdas : ifaceTokens,
    amount: finalAmount,
    seal,
    params: {},
  });
  return invoiceResponse.invoice;
};

const Receive = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    vault,
    type,
    precision,
    locationHistory,
    lnCredentials,
    tokensWalletFunded,
    tokensWalletAddress,
    assetUtxos,
    udasWalletAddress,
    hash,
    contractId: contract,
  } = location.state;
  const [option, setOption] = useState(type || "");
  const [lnInvoice, setLnInvoice] = useState({
    description: "",
    amount: 0,
    invoice: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [rgbInvoice, setRgbInvoice] = useState(""); // TODO: RGB 0.10
  const [lnTxPending, setLnTxPending] = useState(true);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [contractId, setContractId] = useState(contract || "");
  const [amount, setAmount] = useState(1);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const network = window.localStorage.getItem("network");

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (lnInvoice.invoice) {
      const interval = setInterval(async () => {
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const paymentHash = require("light-bolt11-decoder").decode(
          lnInvoice.invoice
        ).sections[5].value;
        const check = await backend.lightning.checkPayment(paymentHash);
        setLnTxPending(!check);
        if (check) clearInterval(interval);
      }, 1000);
      return () => clearInterval(interval);
    }
    Mixpanel.track(`Viewed Receive ${option}`);
  }, [lnInvoice.invoice]);

  useEffect(() => {
    Mixpanel.track(`Viewed Receive ${option}`);
  }, [lnInvoice.invoice]);

  useEffect(() => {
    Mixpanel.track(`Viewed Receive ${option}`);
  }, []);

  if (option === "") {
    return (
      <PageWrapper2
        className=""
        title="Receive"
        handlePageBack={() =>
          navigate("/wallet", {
            state: { wallet: walletData.name, vault, lnCredentials, hash },
          })
        }
        handleCancel={() =>
          navigate("/wallet", {
            state: { wallet: walletData.name, vault, lnCredentials, hash },
          })
        }
        button={
          <RoundedButton
            className="w-full text-lg text-black border-2 border-black dark:text-yellow-500 dark:border-yellow-500"
            onClick={() =>
              navigate("/wallet", {
                state: { wallet: walletData.name, vault, lnCredentials, hash },
              })
            }
          >
            Return to wallet
          </RoundedButton>
        }
      >
        <div className="flex flex-col justify-center w-11/12 m-auto space-y-4 xs:space-y-6">
          <RoundedButton
            className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
            onClick={() => setOption("Bitcoin")}
          >
            Receive Bitcoin on-chain
          </RoundedButton>
          <RoundedButton
            className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setOption("Lightning")}
            disabled={DISABLE_LN || !LNDHUBX}
          >
            Receive Bitcoin Through Lightning
          </RoundedButton>
          <RoundedButton
            className="text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() =>
              tokensWalletFunded
                ? setOption("Asset")
                : setOpenFundAssetsModal(true)
            }
            disabled={!CARBONADO}
          >
            Receive Asset
          </RoundedButton>
          <RoundedButton
            className="hidden text-lg text-black bg-yellow-500 lg:text-xl flex-grow-default"
            onClick={() => setOption("Invoice")}
          >
            Generate Invoice
          </RoundedButton>
        </div>
        <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
          <FundAssets
            walletBalance={addBalance(walletData?.balance)}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
            vault={vault}
            setOpenFundAssetsModal={setOpenFundAssetsModal}
          />
        </Modal>
      </PageWrapper2>
    );
  }

  return (
    <>
      {option === "Bitcoin" && (
        <PageWrapper2
          className=""
          handlePageBack={() => {
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                lnCredentials,
              },
            });
          }}
          title={`Receive ${option}`}
          button={
            <RoundedButton
              className="w-full mr-2 text-base text-black bg-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    lnCredentials,
                    hash,
                  },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="w-full h-auto m-auto bg-gray-100 pt-1.5">
            <div className="w-auto h-auto py-6 m-auto sm:py-12">
              <QRCode
                value={walletData.address}
                className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                size={256}
                viewBox="0 0 256 256"
              />
            </div>
          </div>
          <CopyButton
            title="Wallet Address"
            handleOnClick={() => {
              Mixpanel.track("Copied Wallet Address", {
                Source: "Receive Bitcoin",
              });
              navigator.clipboard.writeText(walletData.address);
              setOpen(true);
            }}
          >
            {walletData.address}
          </CopyButton>
        </PageWrapper2>
      )}
      {option === "Lightning" && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {lnInvoice?.invoice ? (
            <PageWrapper2
              className=""
              handlePageBack={() => {
                navigate(locationHistory.pop()?.replace("#", "") || "/", {
                  state: {
                    ...location.state,
                    wallet: walletData.name,
                  },
                });
              }}
              title="LN Invoice Created"
              button={
                <RoundedButton
                  className="w-full mr-2 text-base text-black bg-yellow-500"
                  onClick={() =>
                    navigate("/wallet", {
                      state: {
                        wallet: walletData.name,
                        vault,
                        lnCredentials,
                        hash,
                      },
                    })
                  }
                >
                  Return to Wallet
                </RoundedButton>
              }
            >
              {lnTxPending ? (
                <>
                  <div className="w-full h-auto m-auto bg-gray-100 pt-1.5">
                    <div className="w-auto h-auto py-6 m-auto sm:py-12">
                      <QRCode
                        value={lnInvoice.invoice}
                        className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                        size={256}
                        viewBox="0 0 256 256"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full text-gray-800 dark:text-gray-300">
                    <p className="w-full mt-3 text-base font-light sm:mt-0 line-clamp-3">
                      <span className="font-medium text-gray-900 dark:text-gray-500">
                        description:
                      </span>{" "}
                      {lnInvoice.description}
                    </p>
                    <p className="mt-1.5 text-base font-light truncate max-w-64 sm:max-w-152 w-full">
                      <span className="font-medium text-gray-900 dark:text-gray-500">
                        amount:
                      </span>{" "}
                      {lnInvoice.amount.toLocaleString()}{" "}
                      {network !== "bitcoin" ? "tSats" : "Sats"}
                    </p>
                    <CopyButton
                      title="Invoice"
                      handleOnClick={() => {
                        navigator.clipboard.writeText(lnInvoice.invoice);
                        setOpen(true);
                      }}
                    >
                      {lnInvoice.invoice}
                    </CopyButton>
                  </div>{" "}
                </>
              ) : (
                <>
                  <h1 className="mx-auto text-2xl font-normal text-center text-black max-w-10/12 xs:text-3xl lg:text-4xl dark:text-green-500">
                    Invoice completed: payment received
                  </h1>
                  <img
                    className="m-auto my-8 w-36 h-36 sm:w-64 sm:h-64 lg:my-12"
                    src="/images/greenCheck.png"
                    alt="green check mark"
                  />
                </>
              )}
            </PageWrapper2>
          ) : (
            <PageWrapper
              className=""
              handlePageBack={() => {
                navigate(locationHistory.pop()?.replace("#", "") || "/", {
                  state: {
                    ...location.state,
                    wallet: walletData.name,
                  },
                });
              }}
              title="Create Lightning Invoice"
              handleCancel={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    lnCredentials,
                    hash,
                  },
                })
              }
              handleSubmit={async () => {
                try {
                  const tokens = await backend.lightning.auth(
                    lnCredentials.login,
                    lnCredentials.password
                  );
                  if ("error" in tokens) {
                    setError({
                      title: "Error Creating LN Invoice",
                      message: `Authentication error: ${tokens.error}`,
                    });
                    setOpenError(true);
                  } else {
                    const invoice = await backend.lightning.createInvoice(
                      lnInvoice.description,
                      lnInvoice.amount,
                      tokens.token
                    );
                    if (!invoice.error) {
                      setLnInvoice({
                        ...lnInvoice,
                        invoice: invoice.payment_request,
                      });
                    } else {
                      setError({
                        title: "Error Creating LN Invoice",
                        message: lndhubError(invoice.error),
                      });
                      setOpenError(true);
                    }
                  }
                } catch (err) {
                  setError({
                    title: "Error Creating LN Invoice",
                    message:
                      (err as Error)?.message ||
                      err?.toString() ||
                      "Unhandled exception",
                  });
                  setOpenError(true);
                }
              }}
            >
              <div className="w-full">
                <TextField
                  type="text"
                  label="description"
                  placeholder="Enter description"
                  className="w-full p-3"
                  onChange={(e) =>
                    setLnInvoice({ ...lnInvoice, description: e.target.value })
                  }
                />
                <TextField
                  type="number"
                  label="amount"
                  placeholder="Enter amount"
                  className="w-full p-3"
                  onChange={(e) =>
                    setLnInvoice({ ...lnInvoice, amount: e.target.value })
                  }
                />
              </div>
            </PageWrapper>
          )}
        </>
      )}
      {(option === "Asset" || option === "UDA") && (
        <PageWrapper2
          className=""
          handlePageBack={() =>
            navigate(locationHistory.pop()?.replace("#", "") || "/", {
              state: {
                ...location.state,
                wallet: walletData.name,
                locationHistory,
              },
            })
          }
          title={`Receive ${option}`}
          button={
            <RoundedButton
              className="w-full text-base text-black bg-yellow-500"
              onClick={async () => {
                const invoice = await setInvoice(
                  vault,
                  contractId,
                  Number(amount),
                  type,
                  precision,
                  assetUtxos
                );
                setRgbInvoice(invoice);
              }}
            >
              Create Invoice
            </RoundedButton>
          }
        >
          <div className="w-11/12 m-auto">
            {option === "Asset" && !rgbInvoice && (
              <TextField
                label="Amount"
                type="number"
                className="w-full p-3"
                onChange={async (e) => {
                  setAmount(e.target.value);
                }}
                placeholder="Enter amount"
              />
            )}
            {option === "UDA" && !rgbInvoice && (
              <TextField
                label="Contract ID"
                className="w-full p-3"
                onChange={async (e) => {
                  setContractId(e.target.value);
                }}
                placeholder="Enter contract ID"
              />
            )}
          </div>
          {rgbInvoice && (
            <>
              <div className="w-full h-auto m-auto bg-gray-100 pt-1.5">
                <div className="w-auto h-auto py-6 m-auto sm:py-12">
                  <QRCode
                    className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                    value={rgbInvoice}
                    size={256}
                    viewBox="0 0 256 256"
                  />
                </div>
              </div>
              <CopyButton
                title="Invoice"
                handleOnClick={() => {
                  Mixpanel.track("Copied Invoice");
                  navigator.clipboard.writeText(rgbInvoice);
                  setOpen(true);
                }}
              >
                {rgbInvoice}
              </CopyButton>
            </>
          )}
        </PageWrapper2>
      )}
      {option === "Invoice" && (
        <PageWrapper2
          className=""
          handlePageBack={() => setOption("")}
          title={`Generate ${option}`}
          button={
            <RoundedButton
              className="w-full mr-2 text-base text-black bg-yellow-500"
              onClick={() =>
                navigate("/wallet", {
                  state: {
                    wallet: walletData.name,
                    vault,
                    hash,
                    lnCredentials,
                  },
                })
              }
            >
              Return to wallet
            </RoundedButton>
          }
        >
          <div className="w-full h-auto m-auto bg-gray-100 pt-1.5">
            <div className="w-auto h-auto pb-6 m-auto sm:pb-12">
              <QRCode
                value={walletData.address}
                className="max-w-[275px] sm:max-w-[375px] sm:w-[90%] h-auto m-auto"
                size={256}
                viewBox="0 0 256 256"
              />
            </div>
          </div>
          <CopyButton
            title="Wallet Address"
            handleOnClick={() => {
              Mixpanel.track("Copied Wallet Address");
              navigator.clipboard.writeText(walletData.address);
              setOpen(true);
            }}
          >
            {walletData.address}
          </CopyButton>
        </PageWrapper2>
      )}
      <CopiedToClipboard open={open} setOpen={setOpen} />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
    </>
  );
};

export default Receive;
