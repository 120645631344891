/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";
import { ThemeContext } from "src/Hooks/ThemeContext";
import Mixpanel from "src/lib/Mixpanel";

const LightDarkToggle = ({ disabled = false }) => {
  const classNames = (...classes) => classes.filter(Boolean).join(" ");
  const { theme, setTheme } = useContext(ThemeContext);
  const [checked, setChecked] = useState(theme === "dark");

  const handleChange = (e) => {
    Mixpanel.track("Toggled Theme", {
      "Old Setting": theme,
      "New Setting": theme === "dark" ? "light" : "dark",
    });
    e.preventDefault();
    if (disabled) {
      return;
    }
    if (setTheme) setTheme(theme === "dark" ? "light" : "dark");
    setChecked(!checked);
  };

  return (
    <div className="relative inline-block w-10 mr-2 align-middle select-none">
      <input
        type="checkbox"
        className={classNames(
          checked
            ? disabled
              ? "right-0 cursor-default"
              : "right-0"
            : disabled
            ? "right-4 cursor-default"
            : "right-4",
          "absolute block rounded-full w-6 h-6 appearance-none cursor-pointer bg-gray-100 dark:bg-newdarkmode-800 focus:ring-offset-0 focus:ring-transparent focus:outline-none transition[right, background-color ease-in-out 0.2s]"
        )}
        checked={checked}
        onChange={() => null}
        onClick={(e) => handleChange(e)}
      />
      <label
        className={classNames(
          checked
            ? disabled
              ? "right-0 bg-gray-100-200 dark:bg-darkmode-400 cursor-default"
              : "right-0 bg-gray-400 dark:bg-newdarkmode-600"
            : disabled
            ? "right-4 bg-gray-100 dark:bg-newdarkmode-600 cursor-default"
            : "right-4 bg-gray-400 dark:bg-newdarkmode-600",
          "block overflow-hidden h-6 rounded-full cursor-pointer dark:bg-darkmode-400 transition[right, background-color ease-in-out 0.2s]"
        )}
        onClick={(e) => handleChange(e)}
      />
    </div>
  );
};

export default LightDarkToggle;
