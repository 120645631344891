/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as backend from "bitmask-segwit";

import { Asset, Collection, Location, UDA } from "src/types";
import TextArea from "src/Components/Inputs/TextArea";
import ErrorModal from "src/Components/Modals/Error";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import lib from "src/lib";
import { updatingAssets } from "src/aux/updateAssets";
import Mixpanel from "src/lib/Mixpanel";
import TextField from "src/Components/Inputs/TextField";
import { ifaceTokens, ifaceUdas } from "src/constants";

const AcceptTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    asset,
    walletData,
    vault,
    lnCredentials,
    locationHistory,
    hash,
    type,
  } = location.state;
  const [consignment, setConsignment] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    Mixpanel.track("Viewed Accept Asset Transaction", { Asset: asset });
  }, []);

  const handleSubmit = async () => {
    if (consignment.length === 0) {
      setError({
        title: `Error creating transaction`,
        message: "Consignment field was left empty",
      });
      setOpen(true);
    } else {
      setLoading(true);
      const ifaceForAccept = type === "UDA" ? ifaceUdas : ifaceTokens;
      try {
        const accept = await backend.rgb.saveTransfer(vault.private.nostrPrv, {
          iface: ifaceForAccept,
          consignment,
        });

        console.debug("accept Transaction: ", accept);
        navigate("/success", {
          state: {
            wallet: walletData.name,
            vault,
            hash,
            lnCredentials,
            consignment,
            transaction: { assetType: "Accepted" },
          },
        });
      } catch (err: any) {
        setError({
          title: `Error accepting transaction`,
          message: err.message,
        });
        setOpen(true);
      }
    }
  };

  return (
    <PageWrapper
      title="Accept Transaction"
      handlePageBack={() => {
        navigate(locationHistory.pop()?.replace("#", "") || "/wallet", {
          state: { ...location.state, wallet: walletData.name },
        });
      }}
      handleCancel={() => {
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        });
      }}
      handleSubmit={() => handleSubmit()}
      loading={loading}
      confirmation
    >
      <div className="w-full">
        <TextArea
          className="w-full p-2 mb-6"
          placeholder="Enter Consignment"
          label="Consignment"
          rows={3}
          onChange={(e: any) => setConsignment(e.target.value)}
        />
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default AcceptTransaction;
